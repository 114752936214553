import { Box, Switch, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import TableButton from './table/TableButton';
import { BsTrash } from 'react-icons/bs';
import AnimatedRow from '@/features/common/motion/AnimatedRow.jsx';
import ModalOverlayComponent from '@/features/common/ModalOverlayComponent.jsx';
import React from 'react';
import TruncatedTextComponent from '@/features/common/TruncatedTextComponent.jsx';
import PropTypes from 'prop-types';

const WHITE = '#ffffff';
const SystemIdResultsTable = ({ data, toggleActive, handleRemove, handleDelete, highlight }) => {
  return (
    <TableContainer mt={3} pt={2} width="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th width="164px">Source</Th>
            <Th width="430px">System ID</Th>
            <Th>Active</Th>
            <Th>Remove</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((val, i) => {
            // Check if the previous row was highlighted and disable it (we only want animations one time)
            const wasPreviousHighlighted = i > 0 && data[i - 1]?.color === highlight;
            if (wasPreviousHighlighted) {
              data[i - 1].color = WHITE;
            }
            return (
              <AnimatedRow
                key={val.label + i}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.3 }}
                viewport={{ once: true }}
                variants={{
                  visible: { opacity: 1, backgroundColor: WHITE },
                  hidden: { opacity: val?.color === highlight ? 0 : 1, backgroundColor: val?.color === highlight ? val.color : WHITE },
                }}
              >
                {val?.overlay ? (
                  <ModalOverlayComponent
                    sx={{
                      textAlign: 'start',
                      backgroundColor: 'orange.50',
                      paddingInlineStart: '1.5rem',
                      paddingInlineEnd: '1.5rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      lineHeight: '1.0rem',
                      borderBottom: '1px',
                      borderColor: 'gray.200',
                    }}
                    loading={false}
                    confirmText="Are you sure you want to remove this system_id?"
                    handleDelete={(e) => handleDelete(e, val)}
                    handleCancel={(e) => handleRemove(e, val, false)}
                  />
                ) : (
                  <React.Fragment>
                    <Td>
                      <TruncatedTextComponent length={30} title="Source" text={val?.label ?? ''} />
                    </Td>
                    <Td>{val?.value}</Td>
                    <Td>
                      <Switch
                        value={''}
                        id="enable"
                        aria-label="enableId"
                        size="sm"
                        colorScheme="cyan"
                        isChecked={val?.is_active || false}
                        onChange={(e) => toggleActive(e, val)}
                      />
                    </Td>
                    <Td>
                      <Tooltip hasArrow placement="top" label="Remove">
                        <Box p="0" as="span">
                          <TableButton aria-label="remove" icon={<BsTrash />} onClick={(e) => handleRemove(e, val, true)} />
                        </Box>
                      </Tooltip>
                    </Td>
                  </React.Fragment>
                )}
              </AnimatedRow>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
SystemIdResultsTable.propTypes = {
  data: PropTypes.array,
  toggleActive: PropTypes.func,
  handleRemove: PropTypes.func,
  handleDelete: PropTypes.func,
  highlight: PropTypes.string,
};
export default SystemIdResultsTable;
