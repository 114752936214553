import { Badge, Box, Button, FormControl, Heading, HStack, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Layout from '../common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubscriptionTypeList } from '../enum/enumSlice';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '../common/select/styles';
import { useLazyGetUsersQuery, useSubscribeUserMutation, useUnsubscribeUserMutation } from '@/app/services/nucleus';
import { createColumnHelper } from '@tanstack/react-table';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { ERROR, LOADING } from '@/utils/constants';
import {
  selectUserSearchStatus,
  selectUserSortBy,
  selectUserSortDirection,
  setUserSearchStatus,
  setUserSortBy,
  setUserSortDirection,
} from '../user/userSlice';
import TableButton from '../common/table/TableButton';
import { BsTrash } from 'react-icons/bs';
import SubscribeUser from './SubscribeUser';
import UnsubscribeUser from './UnsubscribeUser';
import { subscribeUserAction, unsubscribeUserAction } from './subscriptionHelper';
import { useNotification } from '@/hooks/useNotification';

const AlertSubscriptions = () => {
  const dispatch = useDispatch();
  const subscribeUserModal = useDisclosure();
  const unsubscribeUserModal = useDisclosure();
  const columnHelper = createColumnHelper();
  const searchStatus = useSelector(selectUserSearchStatus);
  const sortBy = useSelector(selectUserSortBy);
  const sortDirection = useSelector(selectUserSortDirection);
  const subscriptions = useSelector(selectSubscriptionTypeList);
  const [subscription, setSubscription] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectUser, setSelectUser] = useState({});

  const [getUsers, { isLoading, isError, error: userError }] = useLazyGetUsersQuery();

  const [
    subscribeUser,
    { isLoading: subscribeUserLoading, isSuccess: subscribeUserSuccess, isError: subscribeUserError, error: subscribeUserErrorMsg },
  ] = useSubscribeUserMutation();

  const [
    unsubscribeUser,
    { isLoading: unsubscribeUserLoading, isSuccess: unsubscribeUserSuccess, isError: unsubscribeUserError, error: unsubscribeUserErrorMsg },
  ] = useUnsubscribeUserMutation();

  useEffect(() => {
    if (subscription) {
      getUsers({ subscriptions: subscription?.value })
        .unwrap()
        .then((payload) => {
          setFilteredList(payload.items.filter((user) => user?.subscriptions.includes(subscription?.value)));
        })
        .catch((error) => {
          console.error('Error fetching users:', error);
        });
    }
  }, [subscription]);

  useEffect(() => {
    if (selectUser.id) {
      unsubscribeUserModal.onOpen();
    }
  }, [selectUser]);

  const userColumns = [
    columnHelper.accessor('first_name', {
      header: 'Name',
      cell: (info) => {
        const { first_name, last_name } = info.row.original;
        return `${first_name} ${last_name}`;
      },
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('subscriptions', {
      header: 'Existing Subscriptions',
      cell: (info) => (
        <Box display="flex" flexDirection={'column'} gap={1}>
          {info.getValue().map((sub, index) => (
            <Badge key={sub.trim() + index} colorScheme="purple" mb={1} alignSelf="flex-start">
              {sub}
            </Badge>
          ))}
        </Box>
      ),
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        return (
          <Tooltip hasArrow placement="top" label="Unsubscribe User">
            <Box p="0" as="span">
              <TableButton aria-label="unsubscribe" icon={<BsTrash />} onClick={(e) => handleUnsubscribeUserActions(e, info)} />
            </Box>
          </Tooltip>
        );
      },
      header: 'Action',
    }),
  ];

  const handleUnsubscribeUserActions = (e, info) => {
    e.preventDefault();
    setSelectUser(info.row.original);
    console.log('Selected User:', info.row.original);
  };

  const handleUnsubscribeUser = (userId) => {
    const subscriptionPayload = {
      subscription: selectedSubscription?.value,
    };
    unsubscribeUserAction(unsubscribeUser, userId, subscriptionPayload, (updatedList) => {
      setFilteredList(updatedList);
    });
  };

  const handleModalClose = () => {
    unsubscribeUserModal.onClose();
    setSelectUser({});
  };

  useNotification(unsubscribeUserSuccess, unsubscribeUserModal.onClose, unsubscribeUserSuccess, 'success', 'User unsubscribed successfully');

  const handleUserRemoval = (userId) => {
    setFilteredList((prevList) => prevList.filter((user) => user.id !== userId));
  };

  const handleSubscribeUserActions = () => {
    subscribeUserModal.onOpen();
  };

  const handleSubscribeUser = async (e, userId) => {
    e.preventDefault();
    const subscriptionPayload = {
      subscription: selectedSubscription?.value,
    };

    await subscribeUserAction(subscribeUser, userId, subscriptionPayload);
  };

  const handleSortBy = (column) => {
    dispatch(setUserSortBy(column));
  };

  const handleSortDirection = (direction) => {
    dispatch(setUserSortDirection(direction));
  };

  const handleSearchStatus = (status) => {
    dispatch(setUserSearchStatus(status));
  };

  const handleSubscriptionChange = (val, { action }) => {
    if (action === 'select-option') {
      if (val?.value !== '') {
        getUsers({ subscriptions: val?.value })
          .unwrap()
          .then((payload) => {
            console.log(payload);
            const filtered = payload.items.filter((user) => user.subscriptions.includes(val?.value));
            setFilteredList(filtered);
          })
          .catch((error) => {
            console.log(error);
            setFilteredList([]);
          });
      }
      setSelectedSubscription(val);
    } else if (action === 'clear' || action === 'cancel') {
      setSubscription(null);
      setFilteredList([]);
      setSelectedSubscription(null);
    }
    setSubscription(val);
  };

  return (
    <Box data-testid="alert-subscriptions">
      <Layout pageTitle={'Pact'}>
        <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
          Alert Subscriptions
        </Heading>
        <Box mt={6} p={0}>
          <HStack spacing={4} justifyContent={'space-between'}>
            <FormControl id="subscription_list" maxW={'50%'} py={6}>
              <Select
                aria-label="subscription_type"
                chakraStyles={chakraStyles}
                focusBorderColor="brand.700"
                options={subscriptions}
                onChange={handleSubscriptionChange}
                isClearable
                isSearchable
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                value={subscription}
                placeholder="Select a Subscription Type"
              />
            </FormControl>
            <HStack>
              <Button isDisabled={!subscription} variant="purple" onClick={() => handleSubscribeUserActions()}>
                Subscribe User
              </Button>
            </HStack>
          </HStack>
        </Box>

        <Box mt={6}>
          {subscription && (
            <DataTableSearchAndFiltering
              title="Subscribed Users"
              originalData={filteredList}
              dataColumns={userColumns}
              dataErrorTitle={'Error fetching users'}
              isDataLoading={isLoading}
              isDataError={isError}
              dataErrorMsg={userError?.data?.detail}
              searchColumns={['first_name', 'email']}
              searchStatus={searchStatus}
              isSearchLoading={searchStatus === LOADING}
              isSearchError={searchStatus === ERROR}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleSortBy={handleSortBy}
              handleSortDirection={handleSortDirection}
              handleSearchStatus={handleSearchStatus}
            />
          )}
          <SubscribeUser
            onOpen={subscribeUserModal.onOpen}
            onClose={subscribeUserModal.onClose}
            isOpen={subscribeUserModal.isOpen}
            isLoading={subscribeUserLoading}
            isSuccess={subscribeUserSuccess}
            isError={subscribeUserError}
            errorMsg={subscribeUserErrorMsg}
            onSaveHandler={handleSubscribeUser}
            selectedSubscription={selectedSubscription}
            onSubscribe={setFilteredList}
            onUserRemove={handleUserRemoval}
          />
          <UnsubscribeUser
            user={selectUser}
            onOpen={unsubscribeUserModal.onOpen}
            onClose={handleModalClose}
            isOpen={unsubscribeUserModal.isOpen}
            isLoading={unsubscribeUserLoading}
            isError={unsubscribeUserError}
            errorMsg={unsubscribeUserErrorMsg}
            unsubscribeUserHandler={handleUnsubscribeUser}
            subscriptionName={selectedSubscription?.label}
          />
        </Box>
      </Layout>
    </Box>
  );
};

export default AlertSubscriptions;
