import { Box } from '@chakra-ui/react';
import Layout from '@/features/common/Layout';
import { Outlet } from 'react-router-dom';

const TroubleShooting = () => {
  return (
    <Box data-test-id="pact-troubleshooting">
      <Layout pageTitle={'Pact'}>
        <Outlet />
      </Layout>
    </Box>
  );
};

export default TroubleShooting;