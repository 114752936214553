import { isArrayValue, isRealValue, toNucleusPercent } from '@/utils/helpers';
import { ARCHIVED, DRAFT, LOADED, NEW, NO_RESULTS, PUBLISHED, SEARCHING } from '@/utils/constants.js';

export const WAIT_INTERVAL = 200;
export const searchColumns = ['name'];

export const statusOptions = [
  { label: NEW, value: 'new', colorScheme: 'red' },
  { label: PUBLISHED, value: 'published', colorScheme: 'green' },
  { label: ARCHIVED, value: 'archived', colorScheme: 'purple' },
  { label: DRAFT, value: 'draft', colorScheme: 'yellow' },
];

export const searchFunc = (val, data, columns) => {
  return data.filter((row) => {
    let task = false;
    for (const filter of columns) {
      const sourceVal = String(row[filter]).toLowerCase();
      if (sourceVal.includes(val.toLowerCase())) {
        task = true;
      }
    }
    return task;
  });
};

export const handleFiltering = (val, data, cols, onStatusChange) => {
  let result = data;
  let status = NO_RESULTS;
  if (isArrayValue(data)) {
    result = searchFunc(val, data, cols);
    if (result.length !== 0) {
      status = LOADED;
    }
    onStatusChange(status);
  }
  return result;
};
export const debounceSearch = (e, innerRef, data, cols, onTextChange, onStatusChange, onResult) => {
  onStatusChange(SEARCHING);
  innerRef && window.clearTimeout(innerRef);
  onTextChange(e.target.value);
  innerRef.current = setTimeout(() => {
    searchFacilities(e.target.value, data, cols, onStatusChange, onResult);
  }, WAIT_INTERVAL);
};
const searchFacilities = (val, data, cols, onStatusChange, onResult) => {
  let result = [];
  if (val) {
    if (data?.length > 0) {
      if (val === '') {
        return;
      }
      result = handleFiltering(val, data, cols, onStatusChange);
    } else {
      onStatusChange('');
    }
  } else {
    result = handleFiltering(null, [], cols, onStatusChange);
    onStatusChange('');
  }
  onResult(result);
};

export const handleStatusColor = (status) => {
  switch (status) {
    case DRAFT:
      return 'yellow';
    case PUBLISHED:
      return 'green';
    case ARCHIVED:
      return 'purple';
    case NEW:
      return 'red';
    default:
      return 'gray';
  }
};

export const getClientNamePlaceholder = (type) => {
  switch (type) {
    case 'company':
      return 'Select a Company';
    case 'facility':
      return 'Search for a Facility';
    case 'health_system':
      return 'Select a Health System';
    case 'msp':
      return 'Select an MSP';
    default:
      return 'Select...';
  }
};

export const fieldErrorHelper = (err, formFields, name) => {
  return formFields.map((f) => {
    if (f.name === name) {
      f.valid = false;
      f.error = err?.msg;
    }
    return f;
  });
};

export const parseErrorLocation = (errorMessage, formFields) => {
  const details = errorMessage?.data?.detail || [];
  let errorFields = [];
  for (const err of details) {
    if (err?.loc[1] === 'client' && isRealValue(err?.loc[2])) {
      errorFields = fieldErrorHelper(err, formFields, `client_${err?.loc[2]}`);
    } else if (err?.loc[1] !== '') {
      errorFields = fieldErrorHelper(err, formFields, err?.loc[1]);
    }
  }
  return errorFields;
};

export const calculateContractPayload = (fields) => {
  let contractPayload = { client: {} };
  for (let f of fields) {
    if (f.type === 'hours') {
      contractPayload[f?.name] = isRealValue(f.value) ? Number(f.value) : null;
    } else if (f.type === 'percentage') {
      contractPayload[f?.name] = isRealValue(f.value) ? toNucleusPercent(f.value) : null;
    } else if (f?.name === 'source') {
      contractPayload.source = f.value?.label ?? '';
    } else if (f?.name === 'divisions') {
      contractPayload.divisions = isArrayValue(f.value) ? f.value.map((v) => v.label) : [];
    } else if (f?.name === 'invoice_delivery_method') {
      contractPayload.invoice_delivery_method = f.value?.value;
    } else if (f?.name === 'starts_at' || f?.name === 'ends_at') {
      let timeStamp = null;
      if (f?.value) {
        const year = f.value.getFullYear();
        const month = f.value.getMonth() + 1;
        const day = f.value.getDate();
        timeStamp = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
      }
      contractPayload[f?.name] = timeStamp;
    } else if (f?.key === 'client_row' && f?.fields && f.fields.length > 0) {
      const clientName = f.fields.find((ff) => ff.name === 'client_name');
      contractPayload['client_id'] = clientName?.value?.payload?.id ?? null;
      contractPayload['client'] = clientName?.value?.payload ?? {};
    } else if (f.type === 'select') {
      contractPayload[f?.name] = f?.value?.label.toLowerCase() ?? '';
    } else {
      contractPayload[f?.name] = isRealValue(f.value) ? f.value : null;
    }
  }
  return contractPayload;
};

// NOTE: fields can have nested fields, which we account for in the function below
export const calculateIsFormInvalid = (fields) => {
  const invalidField = fields.find((fm) => {
    let valid = true;
    if (fm?.fields && !fm?.required) {
      valid = fm.fields.every((ff) => ff.valid === true);
    } else {
      valid = fm.valid;
    }
    return !valid;
  });
  if (invalidField) {
    console.info('INVALID FIELD :::', invalidField);
  }
  return invalidField !== undefined;
};

export const setFieldValidation = (fields) => {
  return fields.map((field) => {
    if (field?.fields && !field?.required) {
      return {
        ...field,
        fields: field.fields.map((sub) => {
          if (sub.required && (!isRealValue(sub.value) || sub.value === '')) {
            sub.valid = false;
          }
          return sub;
        }),
      };
    } else if (field.required && (!isRealValue(field.value) || field.value === '')) {
      return {
        ...field,
        valid: false,
      };
    } else {
      return field;
    }
  });
};

export const fieldValidationStatus = (fieldMap) => {
  return !fieldMap.some((fm) => fm.valid === false);
};
