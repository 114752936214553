import { chakraComponents, components } from 'chakra-react-select';
import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const UserSearchInput = (props) => {
  const { isHidden, ...rest } = props;
  return <components.Input {...rest} isHidden={false} />;
};

export const CustomOption = (props) => {
  const { data } = props;
  return (
    <chakraComponents.Option {...props}>
      <Box>
        <Text fontWeight="bold" color="gray.600">
          {data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : 'Name not found'}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {data.email}
        </Text>
      </Box>
    </chakraComponents.Option>
  );
};

export const SingeValueUser = (props) => {
  const { first_name, last_name } = props.data;
  return (
    <chakraComponents.SingleValue {...props}>
      <Box>
        <Text>{`${first_name} ${last_name}`}</Text>
      </Box>
    </chakraComponents.SingleValue>
  );
};

UserSearchInput.propTypes = {
  isHidden: PropTypes.bool,
};

CustomOption.propTypes = {
  data: PropTypes.object,
};

SingeValueUser.propTypes = {
  data: PropTypes.object,
};
