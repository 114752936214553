import React from 'react';
import AnimatedCell from './motion/AnimatedCell';
import LoadingWrapper from './motion/LoadingWrapper';
import { Button, ButtonGroup, Spinner } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ModalOverlayComponent = ({ loading, confirmText, confirmBtnText, cancelBtnText, handleDelete, handleCancel, sx }) => {
  return loading ? (
    <React.Fragment>
      <AnimatedCell colSpan="4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <LoadingWrapper height={'40px'} alignItems="center">
          <Spinner />
        </LoadingWrapper>
      </AnimatedCell>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <AnimatedCell colSpan="2" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} sx={sx || null}>
        {confirmText}
      </AnimatedCell>
      <AnimatedCell colSpan="2" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} sx={sx || null}>
        <ButtonGroup isAttached variant="outline">
          <Button aria-label="yes" onClick={handleDelete}>
            {confirmBtnText || 'Yes'}
          </Button>
          <Button aria-label="no" onClick={handleCancel}>
            {cancelBtnText || 'No'}
          </Button>
        </ButtonGroup>
      </AnimatedCell>
    </React.Fragment>
  );
};

ModalOverlayComponent.propTypes = {
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  sx: PropTypes.object,
};
export default ModalOverlayComponent;
