import { setLogoutStatus, setForceLogout, setFailedRequests } from '@/features/login/loginSlice';
import { setNotificationErrors, setNotificationLogMessage } from '@/features/notification/notificationSlice';
import {
  ALL_CONTRACTS,
  AUTH_USER,
  COMPANY,
  CONTRACT,
  FACILITY,
  FACILITY_MAPPING,
  FEATURES,
  HEALTH_SYSTEM,
  MARGIN_RULES,
  MODALITY,
  MSP,
  RATE,
  REPS,
  SPECIALTY_MAPPING,
  USER,
  CERTIFICATIONS,
  SPECIALTIES,
  PROFESSIONS,
  DATA_DUMPS,
  JUMP_LINKS,
  JOB_CLOSING_RULES,
  MSTOKEN,
} from '@/utils/constants';
import { isArrayValue, parseJobProperties } from '@/utils/helpers';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { marginRulesMutation, marginRulesQuery } from '@/app/services/rulesHelper';
import { v4 as uuid } from 'uuid';
import { addTruncatedProperties } from '@/app/services/helper.js';

const viteHost = import.meta.env.VITE_HOST;
export const hostname = viteHost ? viteHost : 'http://localhost:8000';

export const baseUrl = `${hostname}/api/`;

const baseQuery = (args, api, extraOptions) => {
  const version = extraOptions?.version ?? '1';
  const versionedUrl = `${baseUrl}v${version}/`;
  return fetchBaseQuery({
    baseUrl: versionedUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().login.token;
      if (token && token !== MSTOKEN) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    },
    mode: 'cors',
    credentials: 'include',
  })(args, api, extraOptions);
};

const mappingsQuery = ({ search = '', offset = 0, limit = 100, source = '' }, type) => {
  let query = '';
  if (search) {
    query = `map_from=${search}&`;
    if (source) {
      query = `${query}source=${source}&`;
    }
  } else if (source) {
    query = `source=${source}&`;
  }
  return {
    url: `mappings/${type}?${query}offset=${offset}&limit=${limit}&include=map_to`,
    method: 'GET',
  };
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const status = result?.error?.status;
  const state = api.getState();
  const msg = result?.error?.data?.detail;
  if (result?.error && [401, 429].includes(status)) {
    api.dispatch(setForceLogout(true));
    api.dispatch(setLogoutStatus());
    // do not show error messages if the user is logged out (we check email)
    if (state.login.failedRequests === false && state.user.email !== '') {
      api.dispatch(setNotificationLogMessage({ msg, status: 'error' }));
      api.dispatch(setFailedRequests(true));
    }
  } else if (result?.error && status === 403) {
    api.dispatch(setNotificationLogMessage({ msg, status: 'error' }));
  }
  if (result?.error && status > 400 && ![401, 429].includes(status)) {
    const error = isArrayValue(msg) ? msg : [msg];
    api.dispatch(setNotificationErrors(error));
  }
  return result;
};

// WARN: transformResponse is run on mock responses as well, so it must be able to handle both
export const nucleusApi = createApi({
  reducerPath: 'nucleusApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [CONTRACT, ALL_CONTRACTS, FACILITY, MSP, COMPANY, HEALTH_SYSTEM, RATE, USER],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => {
        return {
          url: `auth/token`,
          method: 'POST',
          body: new URLSearchParams({
            'username': username,
            'password': password,
            'grant_type': 'password',
          }),
        };
      },
    }),
    getMicrosoftAuth: builder.query({
      query: () => {
        return {
          url: `auth/login/microsoft?return_to=${window.location.origin}`,
          method: 'GET',
        };
      },
      providesTags: [AUTH_USER],
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: `auth/logout`,
          method: 'POST',
        };
      },
    }),
    getRoutes: builder.query({
      query: () => {
        return {
          url: 'auth/routes',
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return Object.values(
          response.reduce((acc, { tags, ...rest }, index) => {
            const tag = tags.join(',');
            if (!acc[tag]) {
              acc[tag] = { label: tags.join(','), options: [] };
            }
            acc[tag].options.push(rest);
            return acc;
          }, {}),
        );
      },
    }),
    getAuthUsers: builder.query({
      query: () => {
        return {
          url: `auth/users`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response.items.filter((user) => user.first_name !== '' && user.last_name !== '');
      },
      providesTags: [AUTH_USER],
    }),
    getAuthUser: builder.query({
      query: ({ id }) => {
        return {
          url: `auth/users/${id}`,
          method: 'GET',
        };
      },
      providesTags: [AUTH_USER],
    }),
    addAuthUser: builder.mutation({
      query: ({ authUser }) => {
        return {
          url: 'auth/users',
          method: 'POST',
          body: authUser,
        };
      },
      invalidatesTags: [AUTH_USER],
    }),
    resetAuthUser: builder.mutation({
      query: ({ id }) => {
        return {
          url: `auth/users/${id}/reset`,
          method: 'POST',
          body: id,
        };
      },
      invalidatesTags: [AUTH_USER],
    }),
    disableAuthUser: builder.mutation({
      query: ({ id }) => {
        return {
          url: `auth/users/${id}/disable`,
          method: 'POST',
          body: id,
        };
      },
      invalidatesTags: [AUTH_USER],
    }),
    updateAuthUser: builder.mutation({
      query: ({ id, authUser }) => {
        return {
          url: `auth/users/${id}`,
          method: 'PUT',
          body: authUser,
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [AUTH_USER],
    }),
    updateAuthUserPassword: builder.mutation({
      query: ({ id, password, confirmPassword }) => {
        return {
          url: `auth/users/${id}/password`,
          method: 'PUT',
          body: {
            password: password,
            confirm_password: confirmPassword,
          },
          validateStatus: (response, result) => response.status === 200,
        };
      },
    }),
    contracts: builder.query({
      query: () => {
        return {
          url: `contracts/?limit=0&offset=0`,
          method: 'GET',
        };
      },
      extraOptions: { version: '2' },
      transformResponse: (response) => {
        if (response?.items) {
          return response.items;
        } else {
          return response;
        }
      },
      providesTags: [ALL_CONTRACTS],
    }),
    getContractReps: builder.query({
      query: ({ id }) => {
        return {
          url: `contracts/${id}/sales_reps?include=user&include=facilities`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        if (isArrayValue(response)) {
          const prepareResponse = response.map((rep) => {
            return {
              rep_id: rep?.rep_id,
              divisions: rep?.divisions,
              facility_ids: rep?.facility_ids,
              id: rep?.id,
              name: rep?.user?.first_name + ' ' + rep?.user?.last_name,
              deleted_at: rep?.user?.deleted_at,
              role: rep?.user?.role,
              facility_names: isArrayValue(rep?.facilities) ? rep?.facilities.map((f) => f.name) : [],
              facilities: rep?.facilities,
            };
          });
          const divisions = addTruncatedProperties('divisions', prepareResponse, 22);
          return addTruncatedProperties('facility_names', divisions, 20);
        } else {
          return response;
        }
      },
      providesTags: [REPS],
    }),
    filterContracts: builder.query({
      query: ({ division, source, facility, modality, sales_rep, client, deleted }) => {
        let query = '';
        if (division) {
          query = `${query}division=${division}&`;
        }
        if (source) {
          query = `${query}source=${source}&`;
        }
        if (facility) {
          query = `${query}facility_id=${facility}&`;
        }
        if (modality) {
          query = `${query}rate_card__modality_id=${modality}&`;
        }
        if (sales_rep) {
          query = `${query}sales_rep_associations__rep_id=${sales_rep}&`;
        }
        if (client) {
          query = `${query}client_id=${client}&`;
        }
        if (deleted) {
          query = `${query}is_deleted=${deleted}&`;
        }
        return {
          url: `contracts/?include=facilities&include=rates&include=reps&limit=0&offset=0&${query}`,
          method: 'GET',
        };
      },
      extraOptions: { version: '2' },
    }),
    searchContracts: builder.query({
      query: (body) => {
        const search = body?.search ?? '';
        return {
          url: `contracts/?search=${search}`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response?.items?.map((contract) => ({
          id: contract?.id,
          name: contract?.name,
        }));
      },
      extraOptions: { version: '2' },
      providesTags: [ALL_CONTRACTS],
    }),
    getContract: builder.query({
      query: (body) => {
        const id = body?.id;
        if (id) {
          return {
            url: `contracts/${id}?include=facilities&include=reps&include=rates&include=client`,
            method: 'GET',
          };
        }
      },
      providesTags: [CONTRACT],
    }),
    getEntity: builder.query({
      query: ({ entity, source, systemId }) => {
        if (entity && source && systemId) {
          return {
            url: `entity/${entity}/${source}/${systemId}`,
            method: 'GET',
          };
        }
      },
    }),
    getCertifications: builder.query({
      query: () => {
        return {
          url: 'certifications?limit=0&offset=0',
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response.items.filter((certifications) => certifications.name !== '' && certifications.id !== '');
      },
      providesTags: [CERTIFICATIONS],
    }),
    addCertifications: builder.mutation({
      query: (certification) => {
        return {
          url: 'certifications',
          method: 'POST',
          body: certification,
        };
      },
      invalidatesTags: [CERTIFICATIONS],
    }),
    getSpecialtyMappings: builder.query({
      query: (body) => {
        return mappingsQuery(body, 'specialties');
      },
      providesTags: [SPECIALTY_MAPPING],
    }),
    updateSpecialtyMapping: builder.mutation({
      query: ({ id, mapFrom }) => {
        return {
          url: `mappings/specialties/${id}`,
          method: 'PUT',
          body: { map_from: mapFrom },
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [SPECIALTY_MAPPING],
    }),
    addSpecialtyMapping: builder.mutation({
      query: ({ mapping }) => {
        return {
          url: 'mappings/specialties',
          method: 'POST',
          body: mapping,
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [SPECIALTY_MAPPING],
    }),
    removeSpecialtyMapping: builder.mutation({
      query: ({ id }) => {
        return {
          url: `mappings/specialties/${id}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'pk': id,
          }),
        };
      },
      invalidatesTags: [SPECIALTY_MAPPING],
    }),
    getFacilityMappings: builder.query({
      query: (body) => {
        return mappingsQuery(body, 'facilities');
      },
      providesTags: [FACILITY_MAPPING],
    }),
    updateFacilityMapping: builder.mutation({
      query: ({ id, mapFrom }) => {
        return {
          url: `mappings/facilities/${id}`,
          method: 'PUT',
          body: { map_from: mapFrom },
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [FACILITY_MAPPING],
    }),
    addFacilityMapping: builder.mutation({
      query: ({ mapping }) => {
        return {
          url: 'mappings/facilities',
          method: 'POST',
          body: mapping,
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [FACILITY_MAPPING],
    }),
    removeFacilityMapping: builder.mutation({
      query: ({ id }) => {
        return {
          url: `mappings/facilities/${id}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'pk': id,
          }),
        };
      },
      invalidatesTags: [FACILITY_MAPPING],
    }),
    getFeatures: builder.query({
      query: () => {
        return {
          url: 'features/',
          method: 'GET',
        };
      },
      providesTags: [FEATURES],
    }),
    updateFeature: builder.mutation({
      query: ({ featureId, enabled }) => {
        return {
          url: `features/${featureId}?enable=${enabled}`,
          method: 'PUT',
          validateStatus: (response) => response.status === 200,
        };
      },
      invalidatesTags: [FEATURES],
    }),
    getDataDumps: builder.query({
      query: () => {
        return {
          url: 'dumps/',
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response.items.filter((dumps) => dumps.name !== '');
      },
      providesTags: [DATA_DUMPS],
    }),
    addDataDumps: builder.mutation({
      query: (dataDump) => {
        return {
          url: 'dumps/',
          method: 'POST',
          body: dataDump,
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [DATA_DUMPS],
    }),
    removeDataDump: builder.mutation({
      query: ({ id }) => {
        return {
          url: `dumps/${id}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'pk': id,
          }),
        };
      },
      invalidatesTags: [DATA_DUMPS],
    }),
    addFeature: builder.mutation({
      query: ({ feature }) => {
        return {
          url: 'features',
          method: 'POST',
          body: feature,
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [FEATURES],
    }),
    getJobClosingRules: builder.query({
      query: () => {
        return {
          url: `/jobs/automation/close`,
          method: 'GET',
        };
      },
      providesTags: [JOB_CLOSING_RULES],
    }),
    addJobClosingRule: builder.mutation({
      query: ({ division, close_days }) => {
        return {
          url: `/jobs/automation/close/${division}`,
          method: 'POST',
          body: { close_days },
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [JOB_CLOSING_RULES],
    }),
    getSchema: builder.query({
      query: ({ schema }) => {
        return {
          url: `schemas/${schema}`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        if (response?.title === 'JobFromDB') {
          return parseJobProperties(response);
        } else {
          return response;
        }
      },
    }),
    updateMarginRules: builder.mutation({
      queryFn: async ({ rules }, api, extraOptions, baseQuery) => {
        return marginRulesMutation(`rules/margin`, { rules }, api, extraOptions, baseQuery);
      },
      invalidatesTags: [MARGIN_RULES],
    }),
    rollbackMarginRules: builder.mutation({
      queryFn: async ({ division, version }, api, extraOptions, baseQuery) => {
        return marginRulesMutation(`rules/margin/${division}/rollback/${version}`, { division, version }, api, extraOptions, baseQuery);
      },
    }),
    getAllRuleVersions: builder.query({
      query: ({ division }) => {
        return {
          url: `rules/margin/${division}/versions?_=${uuid()}`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        const items = response.map((value) => {
          const label = new Date(value).toLocaleString();
          return {
            value,
            label,
          };
        });
        return items.sort((a, b) => new Date(b.value) - new Date(a.value));
      },
    }),
    getRuleVersion: builder.query({
      queryFn: async ({ division, version }, api, extraOptions, baseQuery) => {
        return marginRulesQuery(`rules/margin/${division}/versions/${version}`, api, extraOptions, baseQuery);
      },
      providesTags: [MARGIN_RULES],
    }),
    getMarginRules: builder.mutation({
      queryFn: async ({ division }, api, extraOptions, baseQuery) => {
        return marginRulesQuery(`rules/margin/${division}`, api, extraOptions, baseQuery);
      },
      providesTags: [MARGIN_RULES],
    }),
    getEnums: builder.query({
      query: () => {
        return {
          url: 'enums',
          method: 'GET',
        };
      },
    }),
    addContract: builder.mutation({
      query: ({ contract }) => {
        return {
          url: 'contracts/',
          method: 'POST',
          body: contract,
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [ALL_CONTRACTS],
    }),
    removeContract: builder.mutation({
      query: ({ contractId }) => {
        return {
          url: `contracts/${contractId}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'contract_id': contractId,
          }),
        };
      },
      invalidatesTags: [ALL_CONTRACTS],
    }),
    updateContract: builder.mutation({
      query: ({ contractId, contract }) => {
        return {
          url: `contracts/${contractId}`,
          method: 'PUT',
          body: contract,
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS],
    }),
    cloneContract: builder.mutation({
      query: ({ contractId }) => {
        return {
          url: `contracts/${contractId}/copy`,
          method: 'POST',
          body: new URLSearchParams({
            'contract_id': contractId,
          }),
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS],
    }),
    copyContract: builder.mutation({
      query: ({ contractId, copyContractId }) => {
        return {
          url: `contracts/${contractId}/copy/${copyContractId}`,
          method: 'POST',
          body: new URLSearchParams({
            'contract_id': contractId,
            'copy_from_contract_id': copyContractId,
          }),
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS],
    }),
    getMe: builder.query({
      query: () => {
        return {
          url: 'auth/users/me',
          method: 'GET',
        };
      },
    }),
    updateMyPassword: builder.mutation({
      query: ({ password, confirmPassword, currentPassword }) => {
        return {
          url: 'auth/users/me/password/change',
          method: 'PUT',
          body: {
            password: password,
            confirm_password: confirmPassword,
            current_password: currentPassword,
          },
          validateStatus: (response, result) => response.status === 200,
        };
      },
    }),
    jobSources: builder.query({
      query: () => {
        return {
          url: '/jobs/job_sources',
          method: 'GET',
        };
      },
    }),
    getUsers: builder.query({
      query: () => {
        return {
          url: 'users?limit=0&offset=0',
          method: 'GET',
        };
      },
      providesTags: [USER],
      invalidatesTags: [CONTRACT],
    }),
    searchUsers: builder.query({
      query: (body) => {
        const search = body?.search ?? '';
        const limit = body?.limit ?? 100;
        let query = '';
        if (search) {
          query = `users?search=${search}`;
        } else {
          query = `users?limit=${limit}`;
        }
        return {
          url: query,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response?.items?.map((user) => ({
          value: user?.id,
          first_name: user?.first_name,
          last_name: user?.last_name,
          id: user?.id,
          email: user?.email,
          subscriptions: user?.subscriptions,
          payload: user,
        }));
      },
    }),
    subscribeUser: builder.mutation({
      query: ({ userId, subscription }) => {
        return {
          url: `users/${userId}/subscribe`,
          method: 'POST',
          body: subscription,
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [USER],
    }),
    unsubscribeUser: builder.mutation({
      query: ({ userId, subscription }) => {
        return {
          url: `users/${userId}/unsubscribe`,
          method: 'POST',
          body: subscription,
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [USER],
    }),
    getMSPs: builder.query({
      query: (body) => {
        return {
          url: `companies?company_type=msp&limit=0`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response?.items?.map((company) => ({
          value: company?.id,
          label: company?.name,
          city: company?.city,
          state: company?.state,
          address: company?.address,
          payload: company,
        }));
      },
      providesTags: [MSP],
    }),
    getHealthSystems: builder.query({
      query: (body) => {
        return {
          url: `companies?company_type=health_system&limit=0`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response?.items?.map((company) => ({
          value: company?.id,
          label: company?.name,
          city: company?.city,
          state: company?.state,
          address: company?.address,
          payload: company,
        }));
      },
      providesTags: [HEALTH_SYSTEM],
    }),
    getCompanies: builder.query({
      query: (body) => {
        return {
          url: `companies?company_type=company&limit=0`,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response?.items?.map((company) => ({
          value: company?.id,
          label: company?.name,
          id: company?.id,
          city: company?.city,
          state: company?.state,
          address: company?.address,
          payload: company,
        }));
      },
      providesTags: [COMPANY],
    }),
    searchCompanies: builder.query({
      query: (body) => {
        const search = body?.search ?? '';
        const state = body?.state ?? '';
        const limit = body?.limit ?? 100;
        let query = '';
        if (search) {
          query = `companies?search=${search}&company_type=facility`;
          if (state) {
            query = `${query}&state=${state}`;
          }
        } else {
          query = `companies?limit=${limit}`;
        }
        return {
          url: query,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response?.items?.map((company) => ({
          value: company?.id,
          label: company?.name,
          facility_type: company?.facility_type,
          id: company?.id,
          city: company?.city,
          state: company?.state,
          address: company?.address,
          payload: company,
        }));
      },
    }),
    modalities: builder.query({
      query: () => {
        return {
          url: `modalities?limit=0&offset=0&sort_by=division`,
          method: 'GET',
        };
      },
      providesTags: [MODALITY],
    }),
    addModalities: builder.mutation({
      query: (modality) => {
        return {
          url: `modalities`,
          method: 'POST',
          body: modality,
        };
      },
      invalidatesTags: [MODALITY],
    }),
    updateModality: builder.mutation({
      query: ({ id, modality }) => {
        return {
          url: `modalities/${id}`,
          method: 'PUT',
          body: modality,
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [MODALITY],
    }),
    specialties: builder.query({
      query: () => {
        return {
          url: `specialties?limit=0&offset=0`,
          method: 'GET',
        };
      },
      providesTags: [SPECIALTIES],
    }),
    professions: builder.query({
      query: () => {
        return {
          url: `professions?limit=0&offset=0`,
          method: 'GET',
        };
      },
      providesTags: [PROFESSIONS],
    }),
    facilities: builder.query({
      query: (body) => {
        const { limit, search } = body;
        let query = '';
        if (search) {
          query = `search=${search}`;
        } else {
          query = `limit=${limit}`;
        }
        return {
          url: `facilities?${query}`,
          method: 'GET',
        };
      },
      providesTags: [FACILITY],
    }),
    uploadJobs: builder.mutation({
      query: ({ jobs }) => {
        return {
          url: `jobs/sourced_jobs`,
          method: 'POST',
          body: jobs,
          validateStatus: (response, result) => response.status === 201,
        };
      },
    }),
    updateStatus: builder.mutation({
      query: ({ contractId, status }) => {
        return {
          url: `contracts/${contractId}/status/${status}`,
          method: 'PUT',
          body: new URLSearchParams({
            'contract_id': contractId,
            'status': status,
          }),
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS],
    }),
    addFacility: builder.mutation({
      query: ({ contractId, facilityId }) => {
        return {
          url: `contracts/${contractId}/facility/${facilityId}`,
          method: 'POST',
          body: new URLSearchParams({
            'contract_id': contractId,
            'facility_id': facilityId,
          }),
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS],
    }),
    rates: builder.query({
      query: (body) => {
        const { contractId } = body;
        return {
          url: `contracts/${contractId}/rates`,
          method: 'GET',
        };
      },
      providesTags: [RATE],
    }),
    editRate: builder.mutation({
      query: ({
        contractId,
        rateCardId,
        name,
        bill_base_rate,
        bill_40,
        bill_48,
        bill_8,
        bill_12,
        bill_call,
        bill_callback,
        bill_charge,
        bill_holiday,
        bill_base_rate_operator,
        bill_40_operator,
        bill_48_operator,
        bill_8_operator,
        bill_12_operator,
        bill_call_operator,
        bill_callback_operator,
        bill_charge_operator,
        bill_holiday_operator,
      }) => {
        return {
          url: `contracts/${contractId}/rates/${rateCardId}`,
          method: 'PUT',
          body: {
            name,
            bill_base_rate,
            bill_40,
            bill_48,
            bill_8,
            bill_12,
            bill_call,
            bill_callback,
            bill_charge,
            bill_holiday,
            bill_base_rate_operator,
            bill_40_operator,
            bill_48_operator,
            bill_8_operator,
            bill_12_operator,
            bill_call_operator,
            bill_callback_operator,
            bill_charge_operator,
            bill_holiday_operator,
          },
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [CONTRACT],
    }),
    addRate: builder.mutation({
      query: ({
        contractId,
        name,
        bill_base_rate,
        bill_40,
        bill_48,
        bill_8,
        bill_12,
        bill_call,
        bill_callback,
        bill_charge,
        bill_holiday,
        bill_base_rate_operator,
        bill_40_operator,
        bill_48_operator,
        bill_8_operator,
        bill_12_operator,
        bill_call_operator,
        bill_callback_operator,
        bill_charge_operator,
        bill_holiday_operator,
        modalityIds,
      }) => {
        return {
          url: `contracts/${contractId}/rates`,
          method: 'POST',
          body: {
            name,
            bill_base_rate,
            bill_40,
            bill_48,
            bill_8,
            bill_12,
            bill_call,
            bill_callback,
            bill_charge,
            bill_holiday,
            bill_base_rate_operator,
            bill_40_operator,
            bill_48_operator,
            bill_8_operator,
            bill_12_operator,
            bill_call_operator,
            bill_callback_operator,
            bill_charge_operator,
            bill_holiday_operator,
            modality_ids: modalityIds,
          },
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [CONTRACT],
    }),
    removeRate: builder.mutation({
      query: ({ contractId, rateCardId }) => {
        return {
          url: `contracts/${contractId}/rates/${rateCardId}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'contract_id': contractId,
            'rate_card_id': rateCardId,
          }),
        };
      },
      invalidatesTags: [CONTRACT],
    }),
    addModality: builder.mutation({
      query: ({ contractId, rateCardId, modalityId }) => {
        return {
          url: `contracts/${contractId}/rates/${rateCardId}/modalities/${modalityId}`,
          method: 'POST',
          body: new URLSearchParams({
            'contract_id': contractId,
            'rate_card_id': rateCardId,
            'modality_id': modalityId,
          }),
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS],
    }),
    removeModality: builder.mutation({
      query: ({ contractId, rateCardId, modalityId }) => {
        return {
          url: `contracts/${contractId}/rates/${rateCardId}/modalities/${modalityId}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'contract_id': contractId,
            'rate_card_id': rateCardId,
            'modality_id': modalityId,
          }),
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS],
    }),
    addSalesRep: builder.mutation({
      query: ({ contractId, repId, facilityIds, divisions }) => {
        return {
          url: `contracts/${contractId}/sales_rep/`,
          method: 'POST',
          body: {
            rep_id: repId,
            divisions: divisions,
            facility_ids: facilityIds,
          },
          validateStatus: (response, result) => response.status === 201,
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS, REPS],
    }),
    updateSalesRep: builder.mutation({
      query: ({ contractId, repId, repPk, divisions, facilityIds }) => {
        return {
          url: `contracts/${contractId}/sales_rep/${repPk}`,
          method: 'PUT',
          body: {
            rep_id: repId,
            divisions: divisions,
            facility_ids: facilityIds,
          },
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [REPS],
    }),
    removeSalesRep: builder.mutation({
      query: ({ contractId, repId }) => {
        return {
          url: `contracts/${contractId}/sales_rep/${repId}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'contract_id': contractId,
            'sales_rep_association_id': repId,
          }),
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS, REPS],
    }),
    addSalesRepFacility: builder.mutation({
      query: ({ contractId, repId, facilityId }) => {
        return {
          url: `contracts/${contractId}/sales_rep/${repId}/facility/${facilityId}`,
          method: 'POST',
          body: new URLSearchParams({
            'contract_id': contractId,
            'sales_rep_association_id': repId,
            'facility_id': facilityId,
          }),
        };
      },
      invalidatesTags: [CONTRACT, REPS],
    }),
    removeSalesRepFacility: builder.mutation({
      query: ({ contractId, repId, facilityId }) => {
        return {
          url: `contracts/${contractId}/sales_rep/${repId}/facility/${facilityId}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'contract_id': contractId,
            'sales_rep_association_id': repId,
            'facility_id': facilityId,
          }),
        };
      },
      invalidatesTags: [CONTRACT, REPS],
    }),
    transferSalesRep: builder.mutation({
      query: ({ contractId, associationId, repId }) => {
        return {
          url: `contracts/${contractId}/sales_rep/${associationId}/transfer_to/${repId}`,
          method: 'POST',
          body: new URLSearchParams({
            'contract_id': contractId,
            'sales_rep_association_id': associationId,
            'rep_id': repId,
          }),
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS, REPS],
    }),
    removeFacility: builder.mutation({
      query: ({ contractId, facilityId }) => {
        return {
          url: `contracts/${contractId}/facility/${facilityId}`,
          method: 'DELETE',
          body: new URLSearchParams({
            'contract_id': contractId,
            'facility_id': facilityId,
          }),
        };
      },
      invalidatesTags: [CONTRACT, ALL_CONTRACTS, REPS],
    }),
    getJumpLinks: builder.query({
      query: () => {
        return {
          url: `links/`,
          method: 'GET',
        };
      },
      providesTags: [JUMP_LINKS],
    }),
    addJumpLinks: builder.mutation({
      query: ({ source, entity_type, link }) => {
        return {
          url: `links/${source}/${entity_type}`,
          method: 'POST',
          body: { link },
          validateStatus: (response, result) => response.status === 200,
        };
      },
      invalidatesTags: [JUMP_LINKS],
    }),
    searchTracers: builder.query({
      query: ({ search, errorCount, sortBy, limit, offset }) => {
        let query = `streams/tracers?limit=${limit}&offset=${offset}`;
        if (search) {
          query += `&search=${search}`;
        }
        if (errorCount) {
          // filters tracers having an error_count < errorCount
          query += `&error_count=${errorCount}`;
        }
        if (sortBy) {
          query += `&sort_by=${sortBy}`;
        }
        return {
          url: query,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyGetMicrosoftAuthQuery,
  useLogoutMutation,
  useAddFacilityMutation,
  useCopyContractMutation,
  useLazyGetFeaturesQuery,
  useGetFeaturesQuery,
  useGetFacilityMappingsQuery,
  useGetSpecialtyMappingsQuery,
  useRemoveFacilityMappingMutation,
  useRemoveSpecialtyMappingMutation,
  useLazyGetEnumsQuery,
  useGetContractRepsQuery,
  useLazyGetAllRuleVersionsQuery,
  useLazyGetRuleVersionQuery,
  useGetMarginRulesMutation,
  useRollbackMarginRulesMutation,
  useAddRateMutation,
  useGetSchemaQuery,
  useRemoveRateMutation,
  useAddContractMutation,
  useAddFacilityMappingMutation,
  useAddSpecialtyMappingMutation,
  useRemoveContractMutation,
  useUploadJobsMutation,
  useEditRateMutation,
  useJobSourcesQuery,
  useAddSalesRepMutation,
  useUpdateSalesRepMutation,
  useUpdateContractMutation,
  useUpdateFeatureMutation,
  useUpdateMarginRulesMutation,
  useAddFeatureMutation,
  useUpdateMyPasswordMutation,
  useUpdateStatusMutation,
  useUpdateFacilityMappingMutation,
  useUpdateSpecialtyMappingMutation,
  useAddModalityMutation,
  useRemoveModalityMutation,
  useRemoveSalesRepMutation,
  useAddSalesRepFacilityMutation,
  useRemoveSalesRepFacilityMutation,
  useRemoveFacilityMutation,
  useTransferSalesRepMutation,
  useAddAuthUserMutation,
  useResetAuthUserMutation,
  useDisableAuthUserMutation,
  useUpdateAuthUserMutation,
  useUpdateAuthUserPasswordMutation,
  useGetAuthUsersQuery,
  useGetAuthUserQuery,
  useContractsQuery,
  useGetRoutesQuery,
  useGetContractQuery,
  useLazyGetEntityQuery,
  useLazyContractsQuery,
  useLazyFilterContractsQuery,
  useLazySearchContractsQuery,
  useModalitiesQuery,
  useSpecialtiesQuery,
  useProfessionsQuery,
  useAddModalitiesMutation,
  useUpdateModalityMutation,
  useLazyGetMeQuery,
  useGetMeQuery,
  useCloneContractMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetJobClosingRulesQuery,
  useAddJobClosingRuleMutation,
  useGetDataDumpsQuery,
  useAddDataDumpsMutation,
  useRemoveDataDumpMutation,
  useGetJumpLinksQuery,
  useAddJumpLinksMutation,
  useLazySearchCompaniesQuery,
  useLazySearchUsersQuery,
  useGetMSPsQuery,
  useGetCompaniesQuery,
  useGetHealthSystemsQuery,
  useGetCertificationsQuery,
  useAddCertificationsMutation,
  useSubscribeUserMutation,
  useUnsubscribeUserMutation,
  useLazySearchTracersQuery,
} = nucleusApi;

export const {
  endpoints: { contracts, addContract, updateContract, jobSources, facilities, modalities, copy, rates, removeFacility, transferSalesRep },
} = nucleusApi;
