import { HStack, ButtonGroup, Button, Icon, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { BsChevronDoubleLeft, BsChevronLeft, BsChevronRight, BsChevronDoubleRight } from 'react-icons/bs';

const TableFooter = ({ range, setPage, page, ...rest }) => {
  return (
    <HStack justifyContent="end" {...rest}>
      {range.length > 1 && (
        <Text fontSize="xs">
          Page{' '}
          <strong>
            {page} of {range.length}
          </strong>
        </Text>
      )}
      <ButtonGroup variant="outline" spacing="3" size="sm">
        <Button size={'xs'} onClick={() => setPage(1)} isDisabled={page === 1}>
          <Icon boxSize={4} as={BsChevronDoubleLeft} />
        </Button>
        <Button size={'xs'} onClick={() => setPage(page - 1)} isDisabled={page === 1}>
          <Icon boxSize={4} as={BsChevronLeft} />
        </Button>
        <Button size={'xs'} onClick={() => setPage(page + 1)} isDisabled={page === range.length || range.length === 0}>
          <Icon boxSize={4} as={BsChevronRight} />
        </Button>
        <Button size={'xs'} onClick={() => setPage(range.length)} isDisabled={page === range.length || range.length === 0}>
          <Icon boxSize={4} as={BsChevronDoubleRight} />
        </Button>
      </ButtonGroup>
    </HStack>
  );
};

TableFooter.propTypes = {
  range: PropTypes.array,
  setPage: PropTypes.func,
  page: PropTypes.number,
};

export default TableFooter;
