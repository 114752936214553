import { useCallback, useEffect, useMemo, useState } from 'react';
import ModalComponent from '@/features/common/ModalComponent';
import { FaCheckCircle, FaUserEdit } from 'react-icons/fa';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Spinner, Stack } from '@chakra-ui/react';
import { FaCircleXmark } from 'react-icons/fa6';
import AlertComponent from '@/features/common/AlertComponent';
import PasswordInput from '@/features/common/PasswordInput';
import { useSelector } from 'react-redux';
import { selectUserPassword } from '@/features/user/userSlice';
import { useNotification } from '@/hooks/useNotification';
import { passwordRules } from './userHelper';
import PropTypes from 'prop-types';
import ComplexityRules from '../common/ComplexityRules';

const ChangePasswordModal = ({ onOpen, isOpen, onClose, requireCurrentPassword = false, onSubmit, request }) => {
  const currentPassword = useSelector((state) => {
    if (!requireCurrentPassword) {
      return '';
    } else {
      return selectUserPassword(state);
    }
  });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showComplexityRules, setShowComplexityRules] = useState(false);
  const [showMatchRule, setShowMatchRule] = useState(false);
  const [typingPassword, setTypingPassword] = useState(false);
  const [typingConfirmPassword, setTypingConfirmPassword] = useState(false);

  const icons = {
    validIcon: FaCheckCircle,
    invalidIcon: FaCircleXmark,
    validAriaLabel: 'check-icon',
    invalidAriaLabel: 'x-icon',
  };

  const ruleData = useMemo(() => passwordRules(password), [password]);

  const matchRule = useMemo(() => {
    return { label: 'Passwords match', value: password !== '' && password === confirmPassword };
  }, [password, confirmPassword]);

  const hasPassword = useMemo(() => password && password.length > 0 && password !== '', [password]);
  const hasConfirmPassword = useMemo(() => confirmPassword && confirmPassword.length > 0 && confirmPassword !== '', [confirmPassword]);

  useNotification(request.isSuccess, null, request.isSuccess, 'success', 'Password Changed');

  useEffect(() => {
    if (!isOpen) {
      setPassword('');
      setConfirmPassword('');
      setShowComplexityRules(false);
      setShowMatchRule(false);
      setTypingPassword(false);
      setTypingConfirmPassword(false);
      request.reset();
    }
  }, [isOpen, request]);

  const handlePassword = useCallback((e) => {
    setTypingPassword(true);
    const val = e.target.value;
    setShowComplexityRules(Boolean(val));
    setPassword(e.target.value);
  }, []);

  const handleConfirmPassword = useCallback((e) => {
    setTypingConfirmPassword(true);
    const val = e.target.value;
    setShowMatchRule(Boolean(val));
    setConfirmPassword(val);
  }, []);

  const saveUserPassword = (e) => {
    e.preventDefault();
    onSubmit({ password, currentPassword, confirmPassword });
  };

  return (
    <ModalComponent
      icon={<FaUserEdit />}
      size="sm"
      title="Change Password"
      primaryText="Save"
      handleConfirm={saveUserPassword}
      onOpen={onOpen}
      isOpen={isOpen}
      onClose={onClose}
      isError={request.isError || !ruleData.every((val) => val.value === true) || !matchRule.value === true}
      buttonWidth={'100%'}
    >
      <Stack spacing={3}>
        {request.isLoading ? (
          <Flex alignItems="center" justifyContent="center">
            <Spinner color="brand.900" size="lg" />
          </Flex>
        ) : (
          <Box>
            {requireCurrentPassword && (
              <FormControl aria-label={'current-password-control'} my={2}>
                <FormLabel>Current Password</FormLabel>
                <PasswordInput isDisabled label="current password" value={currentPassword} handleChange={() => null} />
              </FormControl>
            )}
            <FormControl isInvalid={typingPassword && !ruleData.every((val) => val.value === true)} aria-label={'password-control'} my={2}>
              <FormLabel>Password</FormLabel>
              <PasswordInput label="password" value={password} handleChange={handlePassword} />
              {!hasPassword && (
                <FormErrorMessage mt={3} mb={5} sx={{ flexBasis: '100%', height: 0 }}>
                  Password is required
                </FormErrorMessage>
              )}
              {showComplexityRules && <ComplexityRules rulesData={ruleData} icons={icons} styleProps={{ marginTop: '8px', marginLeft: '8px' }} />}
            </FormControl>
            <FormControl isInvalid={typingConfirmPassword && !matchRule.value === true} aria-label={'confirm-password-control'} my={2}>
              <FormLabel>Confirm Password</FormLabel>
              <PasswordInput label="confirm password" value={confirmPassword} handleChange={handleConfirmPassword} />
              {!hasConfirmPassword && (
                <FormErrorMessage mt={4} sx={{ flexBasis: '100%', height: 0 }}>
                  Confirm Password is required
                </FormErrorMessage>
              )}
              {showMatchRule && <ComplexityRules rulesData={[matchRule]} icons={icons} styleProps={{ marginTop: '8px', marginLeft: '8px' }} />}
            </FormControl>
            {request.isError && <AlertComponent status="error" title="Failed to change password" description={request.error?.data?.detail} />}
          </Box>
        )}
      </Stack>
    </ModalComponent>
  );
};

ChangePasswordModal.propTypes = {
  onOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  requireCurrentPassword: PropTypes.bool,
  onSubmit: PropTypes.func,
  request: PropTypes.object,
};

export default ChangePasswordModal;
