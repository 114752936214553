import { useUnsubscribeUserMutation } from '@/app/services/nucleus';
import useTable from '@/hooks/useTable';
import PropTypes from 'prop-types';
import { isArrayEmpty, isRealValue } from '@/utils/helpers';
import { Box, Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { BiUserPlus } from 'react-icons/bi';
import SubscribedUserResultsTable from './SubscribedUserResultsTable';
import { unsubscribeUserAction } from './subscriptionHelper';
import UserSearch from './UserSearch';
import TableFooter from '../common/table/TableFooter';

const SubscribeUser = ({
  isOpen,
  onClose,
  onSaveHandler,
  isLoading,
  isSuccess,
  isError,
  errorMsg,
  selectedSubscription,
  onSubscribe,
  onUserRemove,
}) => {
  const initialRef = useRef(null);
  const inputRef = useRef(null);
  const finalRef = useRef(null);
  const [user, setUser] = useState(null);
  const [stagedUsers, setStagedUsers] = useState([]);
  const [showError, setShowError] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [stagingRemoval, setStagingRemoval] = useState(false);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(stagedUsers, page, 4);

  const [
    unSubscribeUser,
    { isLoading: unSubscribeUserLoading, isSuccess: userUnsubscribedSuccessfully, isError: unSubscribeUserError, error: unSubscribeUserErrorMsg },
  ] = useUnsubscribeUserMutation();

  useEffect(() => {
    if (isError) {
      setShowError(true);
      setUser(null);
      setUserInput('');
    }
  }, [isError]);

  useEffect(() => {
    if (isOpen) {
      setUser(null);
      setUserInput('');
      setStagedUsers([]);
      setStagingRemoval(false);
      setShowError(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      setStagedUsers((prev) => {
        // Include the new and existing subscriptions in the subscriptions array
        const updatedUser = {
          ...user,
          subscriptions: [...(user?.subscriptions || []), selectedSubscription?.label],
        };

        return [...prev, updatedUser];
      });
      setUser(null);
      setStagingRemoval(false);
      setUserInput('');
    }
    isRealValue(inputRef) && inputRef?.current?.focus();
  }, [isSuccess]);

  useEffect(() => {
    if (userUnsubscribedSuccessfully) {
      const newUsers = stagedUsers.filter((user) => user?.overlay !== true);
      setStagedUsers(newUsers);
      setUser(null);
      setUserInput('');
      setStagingRemoval(false);
    }
  }, [userUnsubscribedSuccessfully]);

  const handleClose = () => {
    setUser(null);
    setUserInput('');
    onClose();
  };

  const handleUserChange = (value, action) => {
    setUser(value);
    setUserInput(value?.first_name || value?.email);
    setShowError(false);
  };

  const handleAddUser = (e, id) => {
    e.preventDefault();
    onSaveHandler(e, id);

    // Add new user to the parent filteredList
    const newUser = {
      id,
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      subscriptions: [...(user?.subscriptions || []), selectedSubscription?.label],
    };

    onSubscribe((prev) => [...prev, newUser]);
  };

  const handleUnsubscribeUserActions = (e, userId) => {
    e.preventDefault();

    const usersWithOverlay = stagedUsers.map((user) => {
      if (user.id === userId) {
        user = { ...user, overlay: true };
      }
      return user;
    });
    setStagedUsers(usersWithOverlay);
    setStagingRemoval(true);
  };

  const confirmUserUnsubscribe = async (e, userId) => {
    const subscriptionPayload = {
      subscription: selectedSubscription.value,
    };
    await unsubscribeUserAction(unSubscribeUser, userId, subscriptionPayload, setStagedUsers);
    onUserRemove(userId); // Remove user from the parent filteredList
  };

  const cancelUserUnsubscribe = (e, id) => {
    e.preventDefault();
    setStagingRemoval(false);
    const usersWithOverlay = stagedUsers.map((user) => {
      if (user.id === id) {
        user = { ...user, overlay: false };
      }
      return user;
    });
    setStagedUsers(usersWithOverlay);
  };

  return (
    <Modal
      title="Subscribe User"
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id="subscribe_user">
          <ModalHeader>
            <HStack>
              <BiUserPlus />
              <Text>Subscribe Users</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <HStack id="subscribe_user" width="100%">
                <UserSearch
                  initialRef={inputRef}
                  onUserChange={handleUserChange}
                  userPlaceholder={'Search Users'}
                  user={user}
                  input={userInput}
                  isReset={isOpen}
                  width="100%"
                  selectedSubscription={selectedSubscription}
                />
                <Button isLoading={isLoading} isDisabled={isArrayEmpty(user)} variant="cyan" onClick={(e) => handleAddUser(e, user?.id)}>
                  Add
                </Button>
              </HStack>
              <SubscribedUserResultsTable
                data={slice}
                loading={unSubscribeUserLoading}
                userName={user?.name}
                handleDelete={confirmUserUnsubscribe}
                handleCancel={cancelUserUnsubscribe}
                isDisabled={isLoading || unSubscribeUserLoading || stagingRemoval}
                handleRemove={handleUnsubscribeUserActions}
                showEmpty={isArrayEmpty(stagedUsers)}
                showAddError={Boolean(showError && errorMsg)}
                showRemoveError={Boolean(showError && unSubscribeUserError)}
                addErr={errorMsg?.data?.detail}
                removeErr={unSubscribeUserErrorMsg?.data?.detail}
                subscriptionName={selectedSubscription?.label}
              />
              {range && range.length > 1 && (
                <Box width="100%">
                  <TableFooter range={range} setPage={setPage} page={page} />
                </Box>
              )}
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

SubscribeUser.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  errorMsg: PropTypes.string,
  selectedSubscription: PropTypes.object,
  onSubscribe: PropTypes.func,
  onUserRemove: PropTypes.func,
};

export default SubscribeUser;
