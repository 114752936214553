import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';
import { LOADED, LOADING, ERROR } from '@/utils/constants';
import { parseNucleusError } from '@/utils/helpers';

const initialState = {
  searchStatus: '',
  sortBy: 'source',
  sortDirection: 'asc',
};

export const jumpLinksSlice = createSlice({
  name: 'jumpLinks',
  initialState,
  reducers: {
    setSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
  },
});

export const { setJumpLinks, setSearchStatus, setSortBy, setSortDirection } = jumpLinksSlice.actions;

export const selectJumpLinksSearchStatus = (status) => status.links.searchStatus;
export const selectJumpLinksSortBy = (status) => status.links.sortBy;
export const selectJumpLinksSortDirection = (status) => status.links.sortDirection;

export default jumpLinksSlice.reducer;
