import { Badge, Box, Button, Heading, HStack, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { useModalitiesQuery } from '@/app/services/nucleus';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectModalitySearchStatus,
  selectModalitySortBy,
  selectModalitySortDirection,
  setSearchStatus,
  setSortBy,
  setSortDirection,
} from './modalitySlice';
import { createColumnHelper } from '@tanstack/react-table';
import { ERROR, LOADING } from '@/utils/constants';
import { BsPencilSquare, BsPlus } from 'react-icons/bs';
import ModalityModal from './ModalityModal';
import { setNotificationLogMessage } from '../notification/notificationSlice';
import { useRole } from '@/hooks/useRole';
import TableButton from '../common/table/TableButton';

const ModalitiesPage = () => {
  useRole('admin');
  const dispatch = useDispatch();
  const modalityModal = useDisclosure();
  const searchStatus = useSelector(selectModalitySearchStatus);
  const sortBy = useSelector(selectModalitySortBy);
  const sortDirection = useSelector(selectModalitySortDirection);
  const columnHelper = createColumnHelper();

  const [selectedModality, setSelectedModality] = useState(null);
  const [modalMode, setModalMode] = useState('add');

  const { data: modalities, isLoading, isError, error: modalitiesError } = useModalitiesQuery();

  const sortShortName = (a, b, id) => {
    const valueA = a.getValue(id) || 'None';
    const valueB = b.getValue(id) || 'None';
    return valueA.localeCompare(valueB);
  };

  const modalitiesClone = (data) => {
    const processedModality = data.map((item) => {
      const modality = { ...item };
      modality.short_name = modality?.short_name || 'None';
      modality['profession_name'] = modality?.profession?.name || 'None';
      modality['specialty_name'] = modality?.specialty?.name || 'None';
      return modality;
    });
    return processedModality;
  };

  const modalitiesData = modalitiesClone(modalities?.items || []);

  const modalitiesColumns = [
    columnHelper.accessor('short_name', {
      header: 'Short Name',
      cell: (info) => (info.getValue() ? info.getValue() : 'None'),
      sortingFn: sortShortName,
    }),
    columnHelper.accessor('division', {
      header: 'Division',
    }),
    columnHelper.accessor('profession.name', {
      header: 'Profession',
    }),
    columnHelper.accessor('specialty.name', {
      header: 'Specialty',
    }),
    columnHelper.accessor('is_default', {
      header: 'Default',
      cell: (info) => {
        const value = info.getValue();
        return (
          <Badge borderRadius={4} px={2} py={0.5} width="50px" textAlign="center" colorScheme={value ? 'green' : 'red'}>
            {value ? 'True' : 'False'}
          </Badge>
        );
      },
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        const modality = info.row.original;
        return (
          <HStack>
            <Tooltip label="Edit Modality" aria-label="Edit Modality">
              <Box p="0" as="span">
                <TableButton aria-label="edit-modality" icon={<BsPencilSquare />} onClick={(e) => handleEditModality(modality)} />
              </Box>
            </Tooltip>
          </HStack>
        );
      },
      header: 'Action',
    }),
  ];

  const handleSearchStatus = (status) => {
    dispatch(setSearchStatus(status));
  };

  const handleSortBy = (column) => {
    dispatch(setSortBy(column));
  };

  const handleSortDirection = (direction) => {
    dispatch(setSortDirection(direction));
  };

  const handleNewModality = () => {
    setSelectedModality(null);
    setModalMode('add');
    modalityModal.onOpen();
  };

  const handleEditModality = (modality) => {
    setSelectedModality(modality);
    setModalMode('edit');
    modalityModal.onOpen();
  };

  const handleSaveNotification = () => {
    dispatch(setNotificationLogMessage({ msg: `Modality ${modalMode === 'edit' ? 'updated' : 'added'} successfully`, status: 'success' }));
  };

  return (
    <React.Fragment>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Modalities
      </Heading>
      <DataTableSearchAndFiltering
        title="Modalities"
        originalData={modalitiesData}
        searchStatus={searchStatus}
        isSearchLoading={searchStatus === LOADING}
        isSearchError={searchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError}
        dataErrorMsg={modalitiesError?.data?.detail}
        dataErrorTitle={'Error fetching modalities'}
        dataColumns={modalitiesColumns}
        searchColumns={['short_name', 'profession_name', 'specialty_name']}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleSortBy}
        handleSortDirection={handleSortDirection}
        handleSearchStatus={handleSearchStatus}
      >
        <Button leftIcon={<BsPlus />} variant="purple" type="submit" onClick={handleNewModality}>
          Add Modality
        </Button>
      </DataTableSearchAndFiltering>
      <ModalityModal
        onOpen={modalityModal.onOpen}
        onClose={modalityModal.onClose}
        isOpen={modalityModal.isOpen}
        mode={modalMode}
        initialValues={selectedModality}
        onSave={handleSaveNotification}
      />
    </React.Fragment>
  );
};

export default ModalitiesPage;
