import { setUserLoggedOut } from '../user/userSlice';
import { setForceLogout, setLogoutStatus } from './loginSlice';
import { nucleusApi } from '@/app/services/nucleus';
import { setNotificationLogMessage } from '@/features/notification/notificationSlice';
import { setGetContractStatus } from '@/features/contract/contractSlice';

export const handleLogoutAction = () => async (dispatch) => {
  dispatch(nucleusApi.util.resetApiState());
  dispatch(setForceLogout(true));
  dispatch(setUserLoggedOut());
  dispatch(setLogoutStatus());
  dispatch(setNotificationLogMessage({ msg: '' }));
  dispatch(setGetContractStatus(''));
  if (typeof window !== 'undefined') {
    window.sessionStorage.clear();
  }
};
