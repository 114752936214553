import { useGetJumpLinksQuery } from '@/app/services/nucleus';
import { useRole } from '@/hooks/useRole';
import { Box, Button, Heading, HStack, Tooltip, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { BsPencilSquare, BsPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectJumpLinksSearchStatus,
  selectJumpLinksSortBy,
  selectJumpLinksSortDirection,
  setSearchStatus,
  setSortBy,
  setSortDirection,
} from './jumpLinksSlice';
import { ERROR, LOADING } from '@/utils/constants';
import TableButton from '../common/table/TableButton';
import JumpLinksModal from './JumpLinksModal';
import { setNotificationLogMessage } from '../notification/notificationSlice';

const JumpLinksPage = () => {
  useRole('support');
  const columnHelper = createColumnHelper();
  const dispatch = useDispatch();
  const jumpLinksModal = useDisclosure();

  const searchStatus = useSelector(selectJumpLinksSearchStatus);
  const sortBy = useSelector(selectJumpLinksSortBy);
  const sortDirection = useSelector(selectJumpLinksSortDirection);

  const [selectedLink, setSelectedLink] = useState(null);
  const [modalMode, setModalMode] = useState('add');

  const { data: jumpLinks, isLoading, isError, error: jumpLinksError } = useGetJumpLinksQuery();

  const jumpLinksColumns = [
    columnHelper.accessor('source', {
      header: 'Source',
    }),
    columnHelper.accessor('entity_type', {
      header: 'Entity Type',
    }),
    columnHelper.accessor('link', {
      header: 'Link',
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        const links = info.row.original;
        return (
          <HStack>
            <Tooltip label="Edit Link" aria-label="edit-jump-link">
              <Box p="0" as="span">
                <TableButton aria-label="edit-jump-link" icon={<BsPencilSquare />} onClick={(e) => handleEditJumpLink(links)} />
              </Box>
            </Tooltip>
          </HStack>
        );
      },
      header: 'Action',
    }),
  ];

  const handleNewJumpLink = () => {
    setSelectedLink(null);
    setModalMode('add');
    jumpLinksModal.onOpen();
  };

  const handleEditJumpLink = (link) => {
    setSelectedLink(link);
    setModalMode('edit');
    jumpLinksModal.onOpen();
  };

  const handleSortBy = (column) => {
    dispatch(setSortBy(column));
  };

  const handleSortDirection = (direction) => {
    dispatch(setSortDirection(direction));
  };

  const handleSearchStatus = (status) => {
    dispatch(setSearchStatus(status));
  };

  const handleSaveNotification = () => {
    dispatch(setNotificationLogMessage({ msg: `Jump Link ${modalMode === 'edit' ? 'updated' : 'added'} successfully`, status: 'success' }));
  };

  return (
    <>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Jump Links
      </Heading>
      <DataTableSearchAndFiltering
        title="Jump Links"
        originalData={jumpLinks}
        searchStatus={searchStatus}
        isSearchLoading={searchStatus === LOADING}
        isSearchError={searchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError || jumpLinksError}
        dataErrorMsg={jumpLinksError?.data?.detail}
        dataColumns={jumpLinksColumns}
        searchColumns={['source', 'entity_type', 'link']}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleSortBy}
        handleSortDirection={handleSortDirection}
        handleSearchStatus={handleSearchStatus}
      >
        <Button leftIcon={<BsPlus />} variant="purple" onClick={handleNewJumpLink} type="submit">
          New
        </Button>
      </DataTableSearchAndFiltering>
      <JumpLinksModal
        onOpen={jumpLinksModal.onOpen}
        isOpen={jumpLinksModal.isOpen}
        onClose={jumpLinksModal.onClose}
        mode={modalMode}
        onSave={handleSaveNotification}
        initialValue={selectedLink}
        jumpLinks={jumpLinks}
      />
    </>
  );
};

export default JumpLinksPage;
