import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import AlertComponent from '../common/AlertComponent';
import { BiUserMinus } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';

const UnsubscribeUser = ({ user, isOpen, onClose, isLoading, isError, errorMessage, unsubscribeUserHandler, subscriptionName }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const handleUnsubscribeUser = (id) => {
    unsubscribeUserHandler(id);
  };

  return (
    <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <BiUserMinus />
            <Text>Unsubscribe from {subscriptionName}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={3}>
            {isLoading ? (
              <Flex alignItems="center" justifyContent="center">
                <Spinner color="brand.900" size="lg" />
              </Flex>
            ) : isError ? (
              <AlertComponent status="error" title={'Error unsubscribing user.'} description={errorMessage || 'Please contact an administrator'} />
            ) : (
              <React.Fragment>
                <Text>Are you sure you want to unsubscribe this user?</Text>
                <AlertComponent
                  icon={<FaUser style={{ height: '36px', width: '36px' }} />}
                  title={`${user?.first_name} ${user?.last_name}`}
                  description={`${user?.email}`}
                />
              </React.Fragment>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="cyan" mr={3} onClick={() => handleUnsubscribeUser(user?.id)}>
            Yes
          </Button>
          <Button onClick={onClose}>No</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

UnsubscribeUser.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  unsubscribeUserHandler: PropTypes.func,
  subscriptionName: PropTypes.string,
};

export default UnsubscribeUser;
