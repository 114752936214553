import PropTypes from 'prop-types';
import { FormLabel, Grid, useColorModeValue } from '@chakra-ui/react';
import { isRealString, isRealValue } from '@/utils/helpers.js';

const FormRow = ({ id, label, children, templateColumns, width, gap }) => {
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const defaultColumns = {
    base: '1fr',
    md: '1fr 4fr',
  };
  const defaultWidth = {
    base: '100%',
    md: '90%',
  };
  return (
    <Grid templateColumns={templateColumns || defaultColumns} gap={isRealValue(gap) ? gap : 4} alignItems="center" width={width || defaultWidth}>
      <FormLabel
        htmlFor={id ?? ''}
        mb="0"
        textAlign={{
          base: 'start',
          md: 'end',
        }}
        color={labelColor}
      >
        {isRealString(label) ? label : ''}
      </FormLabel>
      {children}
    </Grid>
  );
};

FormRow.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  gap: PropTypes.number,
  children: PropTypes.node,
  templateColumns: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FormRow;
