import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { Flex, Box, FormControl, FormLabel, Input, Stack, Button, Heading, useColorModeValue, Spinner } from '@chakra-ui/react';
import { useLoginMutation, useLazyGetMeQuery, useLazyGetMicrosoftAuthQuery, baseUrl, hostname } from '@/app/services/nucleus';
import { selectUserUsername, selectUserPassword, setUserPassword, setUserUsername, setUserData } from '../user/userSlice';
import { setNotificationLogMessage } from '../notification/notificationSlice';
import {
  setLoginStatus,
  setLoginToken,
  setLoginExpiration,
  selectLoginStatus,
  selectLoginExpiration,
  selectLoginToken,
  selectForceLogout,
  setForceLogout,
  setFailedRequests,
} from './loginSlice';
import { LOGGED_OUT, LOADED, TOKEN_RECEIVED, LOGIN_ERROR } from '@/utils/constants';
import triageLogo from '/triage.svg';
import PasswordInput from '@/features/common/PasswordInput';
import { BsMicrosoft } from 'react-icons/bs';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loginStatus = useSelector(selectLoginStatus);
  const username = useSelector(selectUserUsername);
  const password = useSelector(selectUserPassword);
  const isForceLogout = useSelector(selectForceLogout);
  const expiration = useSelector(selectLoginExpiration);
  const token = useSelector(selectLoginToken);
  const [ready, setReady] = useState(false);
  const [userSession, setUserSession] = useSessionStorage('pact_user', {});
  const [pactData, setPactData] = useSessionStorage('pact_data', {});
  const [pathName, setPathName] = useState(null);

  useEffect(() => {
    const { from } = location.state || {};
    const { pathname, search } = from || {};
    // consider jsdom
    const origin = window?.location ? window.location.origin : 'http://localhost';
    const params = search ? encodeURIComponent(search) : '';
    setPathName(origin + (pathname && pathname !== '/401' ? pathname : '/') + params);
  }, [location]);

  const [login] = useLoginMutation();
  const [microsoft] = useLazyGetMicrosoftAuthQuery();
  const [trigger] = useLazyGetMeQuery();

  // handle persisting user name, token and expiration
  useEffect(() => {
    if (loginStatus) {
      switch (loginStatus) {
        case TOKEN_RECEIVED:
          setUserSession({
            token,
            expiration,
          });
          setReady(true);
          break;
        case LOGGED_OUT:
          if (!isForceLogout) {
            dispatch(
              setNotificationLogMessage({
                status: 'success',
                msg: 'You have been successfully logged out',
              }),
            );
            dispatch(setForceLogout(false));
          }
          setReady(false);
          break;
        default:
          break;
      }
    } else {
      if (userSession && Object.keys(userSession).length > 0) {
        dispatch(setLoginToken(userSession.token));
        dispatch(setLoginExpiration(userSession.expiration));
        trigger()
          .unwrap()
          .then((payload) => {
            dispatch(
              setUserData({
                id: payload?.id,
                roles: payload?.roles,
                admin: payload?.admin,
                superadmin: payload?.superadmin,
                email: payload?.email,
                name: `${payload?.first_name} ${payload?.last_name}`,
                username: payload?.username,
              }),
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    if (loginStatus !== LOGGED_OUT) {
      dispatch(setLoginStatus(LOADED));
      dispatch(setFailedRequests(false));
    }
  }, []);

  useEffect(() => {
    if (ready) {
      // NOTE: 'from' state is defined in <PrivateOutlet />
      const { from } = location.state || {};
      const { pathname, search, state } = from || {};
      // do not redirect the user back to a 401 page
      navigate((pathname && pathname !== '/401' ? pathname : '/') + (search ?? ''), { replace: true, state });
    }
  }, [ready]);

  const handleLogin = (e) => {
    login({ username: username, password: password })
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMSLogin = (e) => {
    microsoft()
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleLogin(e);
    }
  };

  const handleUsernameChange = (event) => dispatch(setUserUsername(event.target.value));
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    dispatch(setUserPassword(value));
    setPactData({ key: btoa(value) });
  };

  const gray800 = useColorModeValue('gray.100', 'gray.800');
  const white700 = useColorModeValue('white', 'gray.700');

  return (
    <Box>
      <Flex alignItems="center" justifyContent="center" bg={gray800}>
        <Flex minH={'100vh'} align={'center'} justify={'center'} bg={gray800}>
          {typeof loginStatus === 'undefined' || (loginStatus !== LOADED && loginStatus !== LOGIN_ERROR && loginStatus !== LOGGED_OUT) ? (
            <Spinner color="brand.900" size="lg" />
          ) : (
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
              <Stack align={'center'}>
                <img src={triageLogo} className="logo" alt="Triage logo" />
                <Heading as="h2" size="lg" color={'gray.600'}>
                  Pact
                </Heading>
              </Stack>
              <Box rounded={'lg'} bg={white700} boxShadow={'lg'} p={8}>
                <Stack spacing={4}>
                  <FormControl id="email">
                    <FormLabel>Username</FormLabel>
                    <Input
                      id="pact-username"
                      autoComplete="true"
                      type="text"
                      value={username}
                      onKeyDown={(event) => onEnterPress(event)}
                      onChange={handleUsernameChange}
                    />
                  </FormControl>
                  <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <PasswordInput
                      id="pact-pw"
                      label="password"
                      value={password}
                      onKeyDown={(event) => onEnterPress(event)}
                      handleChange={handlePasswordChange}
                    />
                  </FormControl>
                  <Stack spacing={5}>
                    <Button variant="purple" onClick={(e) => handleLogin(e)}>
                      Sign in
                    </Button>
                    <Button leftIcon={<BsMicrosoft />} variant="blue">
                      <Link to={`${baseUrl}v1/auth/login/microsoft?return_to=${pathName}`} style={{ color: '#fff' }}>
                        Sign in with Microsoft
                      </Link>
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default LoginPage;
