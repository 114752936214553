import { AbsoluteCenter, Badge, Box, Divider } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const DataHeading = ({ label, empty, bg }) => {
  return (
    <Box pos="relative" width="100%" p={4} key={label}>
      <Divider />
      <AbsoluteCenter bg="white" px={4}>
        <i>{label + ' '}</i>
        {empty && <Badge colorScheme={bg}>Empty</Badge>}
      </AbsoluteCenter>
    </Box>
  );
};

DataHeading.propTypes = {
  label: PropTypes.string,
  empty: PropTypes.bool,
  bg: PropTypes.string,
};
export default DataHeading;
