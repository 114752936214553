import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaLink } from 'react-icons/fa';
import LoaderOverlay from '../common/LoaderOverlay';
import { useAddJumpLinksMutation } from '@/app/services/nucleus';
import AlertComponent from '../common/AlertComponent';
import { CreatableSelect, Select } from 'chakra-react-select';
import { chakraStyles } from '../common/select/styles';
import { useSelector } from 'react-redux';
import { selectEntityTypeList, selectEventSourceList } from '../enum/enumSlice';

const JumpLinksModal = ({ isOpen, onClose, onSave, initialValue, mode, jumpLinks }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const eventSourceList = useSelector(selectEventSourceList);
  const entityTypeOption = useSelector(selectEntityTypeList);

  const [eventSourceOptions, setEventSourceOptions] = useState(eventSourceList);
  const [eventSource, setEventSource] = useState([]);
  const [entityType, setEntityType] = useState([]);
  const [jumpLink, setJumpLink] = useState('');
  const [linkError, setLinkError] = useState('');
  const [eventSourceError, setEventSourceError] = useState('');
  const [entityTypeError, setEntityTypeError] = useState('');

  const [
    addJumpLink,
    {
      isLoading: isAddJumpLinkLoading,
      isSuccess: isAddJumpLinkSuccess,
      isError: isAddJumpLinkError,
      error: addJumpLinkError,
      reset: resetAddJumpLink,
    },
  ] = useAddJumpLinksMutation();

  useEffect(() => {
    if (!isOpen && mode === 'add') {
      setEventSource([]);
      setEntityType([]);
      setJumpLink('');
      setLinkError('');
      setEventSourceError('');
      setEntityTypeError('');
      resetAddJumpLink();
    }
  }, [isOpen, mode]);

  useEffect(() => {
    if (initialValue) {
      const { source: eventSourceValue, entity_type: entityTypeValue, link: jumpLinkValue } = initialValue;

      if (eventSourceValue && !eventSource?.value) {
        const eventSourceItem = eventSourceOptions.find((item) => item.value === eventSourceValue);
        setEventSource(eventSourceItem || { label: eventSourceValue, value: eventSourceValue });
      }

      if (entityTypeValue && !entityType?.value) {
        const entityTypeItem = entityTypeOption.find((item) => item.value === entityTypeValue);
        setEntityType(entityTypeItem || { label: entityTypeValue, value: entityTypeValue });
      }

      setJumpLink(jumpLinkValue?.replace('https://', ''));
    } else {
      setEventSource([]);
      setEntityType([]);
      setJumpLink('');
      setLinkError('');
      setEventSourceError('');
      setEntityTypeError('');
    }
  }, [initialValue]);

  const validateFields = () => {
    const fieldErrors = {};

    // Validate Event Source
    if (!eventSource || !eventSource?.value) {
      fieldErrors.eventSource = 'Please select or create an Event Source.';
    }

    // Validate Entity Type
    if (!entityType || !entityType?.value) {
      fieldErrors.entityType = 'Please select an Entity Type.';
    }

    // Validate Link
    const urlRegex = /^[^.]+(?:\.[^.]+)+$/;
    if (!urlRegex.test(jumpLink)) {
      fieldErrors.link = 'Please enter a valid link (e.g., example.com/{system_id})';
    }

    // Find Jump Link with matching source and entity type
    const existing = jumpLinks.find((link) => link.source === eventSource?.value && link.entity_type === entityType?.value);

    if (existing && mode === 'add') {
      fieldErrors.eventSource = 'Jump Link already exists for this Event Source';
      fieldErrors.entityType = 'Jump Link already exists for this Entity Type';
    }

    return fieldErrors;
  };

  const createOption = (label) => ({
    label: label.toLowerCase().replace(/\W/g, ''),
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  const handleCreateEventSource = (inputValue) => {
    const newOption = createOption(inputValue);

    setEventSourceOptions((prev) => [...prev, newOption]);
    setEventSource(newOption);
    setEventSourceError('');
  };

  const handleEventSource = (options) => {
    setEventSource(options);
    setEventSourceError('');
  };

  const handleEntityType = (options) => {
    setEntityType(options);
    setEntityTypeError('');
  };

  const handleLinkInput = (value) => {
    setJumpLink(value);
    if (linkError) {
      setLinkError('');
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetAddJumpLink();

    // Field Validation
    const errors = validateFields();

    // Set errors in state
    setEventSourceError(errors.eventSource || '');
    setEntityTypeError(errors.entityType || '');
    setLinkError(errors.link || '');

    // If there are errors, stop submission
    if (Object.keys(errors).length) {
      return;
    }

    // Clear errors before submitting
    setEventSourceError('');
    setEntityTypeError('');
    setLinkError('');

    const fullLink = `https://${jumpLink}`;

    const linksPayload = {
      source: eventSource?.value,
      entity_type: entityType?.value,
      link: fullLink,
    };

    addJumpLink(linksPayload)
      .unwrap()
      .then((payload) => {
        console.log(`Jump Link ${mode}ed successfully`, payload);
        onSave();
        onClose();
      })
      .catch((error) => {
        console.log(`Error ${mode}ing jump link`, error);
      });
  };

  return (
    <Modal
      title={mode === 'edit' ? 'Edit Jump Link' : 'New Jump Link'}
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id={`${mode}_jump_link`} onSubmit={handleSubmit}>
          <ModalHeader>
            <HStack>
              <FaLink />
              <Text>{mode === 'edit' ? 'Edit Jump Link' : 'Add Jump Link'}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} position={'relative'}>
              <LoaderOverlay isLoading={isAddJumpLinkLoading} />

              {isAddJumpLinkError && (
                <AlertComponent
                  status="error"
                  title={mode === 'edit' ? 'Error updating jump link' : 'Error adding jump link'}
                  description={addJumpLinkError?.data?.detail}
                />
              )}

              <FormControl id="source" isInvalid={!!eventSourceError} aria-label="source-control">
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="source" mb={0} textAlign="end">
                      Source
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <CreatableSelect
                      id="source"
                      aria-label="source"
                      chakraStyles={chakraStyles}
                      colorScheme="cyan"
                      onChange={handleEventSource}
                      onCreateOption={handleCreateEventSource}
                      options={eventSourceOptions}
                      value={eventSource}
                      isDisabled={mode === 'edit'}
                      placeholder="Select or create an event source"
                      isSearchable
                      isClearable
                    />
                    <FormErrorMessage aria-label="event_source_error">{eventSourceError}</FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl id="entity_type" aria-label="entity_type_control" isInvalid={!!entityTypeError}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="entity_type" mb={0} textAlign="end">
                      Entity Type
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      id="entity_type"
                      aria-label="entity_type"
                      chakraStyles={chakraStyles}
                      options={entityTypeOption}
                      onChange={handleEntityType}
                      value={entityType}
                      colorScheme="cyan"
                      isDisabled={mode === 'edit'}
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => `${option.value}`}
                      placeholder="Select an entity type"
                      isClearable
                    />
                    <FormErrorMessage aria-label="entity_type_error">{entityTypeError}</FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl id="link" aria-label="link_control" isInvalid={!!linkError}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="link" mb={0} textAlign="end" id="link-label">
                      Link
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <InputGroup size="md">
                      <InputLeftAddon>https://</InputLeftAddon>
                      <Input
                        id="link"
                        aria-labelledby="link-label"
                        type="text"
                        placeholder="example.com/{system_id}"
                        _placeholder={{ color: labelColor }}
                        value={jumpLink}
                        onChange={(e) => handleLinkInput(e.target.value)}
                      />
                    </InputGroup>
                    <FormErrorMessage aria-label="link_error">{linkError}</FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>

              <HStack width="100%" py={3} justifyContent={'end'}>
                <Button variant="purple" type="submit">
                  {mode === 'edit' ? 'Save' : 'Submit'}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default JumpLinksModal;
