import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { setupListeners } from '@reduxjs/toolkit/query';
import { nucleusApi } from './services/nucleus';
import userReducer from '../features/user/userSlice';
import mappingReducer from '../features/mapping/mappingSlice';
import contractReducer from '../features/contract/contractSlice';
import facilityReducer from '../features/facility/facilitySlice';
import modalityReducer from '../features/modality/modalitySlice';
import marginReducer from '../features/margin/marginSlice';
import loginReducer from '../features/login/loginSlice';
import flagReducer from '../features/flags/flagSlice';
import enumReducer from '../features/enum/enumSlice';
import jobReducer from '../features/job/jobSlice';
import notificationReducer from '../features/notification/notificationSlice';
import certificationsReducer from '../features/certifications/certificationsSlice';
import dataDumpsReducer from '../features/dataDump/dataDumpSlice';
import jobClosingReducer from '../features/jobClosing/jobClosingSlice';
import jumpLinksReducer from '../features/links/jumpLinksSlice';

const isProduction = import.meta.env.PROD === true;
const isStagingOrUat = import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'uat' || import.meta.env.MODE === 'qa';

// NOTE: logger must be last in the chain
const logger = createLogger({
  diff: true,
  colors: {
    prevState: () => '#7D72B7',
    action: () => '#008AFC',
    nextState: () => '#16C0FF',
    error: () => '#E64747',
  },
  level: 'info',
  collapsed: (getState, action, logEntry) => !action.payload,
  predicate: (getState, action) => !action.type.includes('nucleusApi') && !action.type.includes('__rtkq'),
});

let middleware = [nucleusApi.middleware];
if (!isStagingOrUat && !isProduction && import.meta.env.MODE !== 'test') {
  middleware.push(logger);
}

const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  flag: flagReducer,
  enum: enumReducer,
  job: jobReducer,
  contract: contractReducer,
  facility: facilityReducer,
  modality: modalityReducer,
  margin: marginReducer,
  mapping: mappingReducer,
  certifications: certificationsReducer,
  notification: notificationReducer,
  dumps: dataDumpsReducer,
  jobs: jobClosingReducer,
  links: jumpLinksReducer,

  [nucleusApi.reducerPath]: nucleusApi.reducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
    devTools: !isProduction,
  });
};

export const store = setupStore();
setupListeners(store.dispatch);
