import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TbLayoutCards } from 'react-icons/tb';
import LoaderOverlay from '../common/LoaderOverlay';
import { chakraStyles } from '../common/select/styles';
import { Select, CreatableSelect } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import { selectDivisionsList, selectSystemIdList } from '../enum/enumSlice';
import { useAddModalitiesMutation, useProfessionsQuery, useSpecialtiesQuery, useUpdateModalityMutation } from '@/app/services/nucleus';
import AlertComponent from '../common/AlertComponent';
import { useRole } from '@/hooks/useRole';
import FormRow from '@/features/user/FormRow';
import SystemIdResultsTable from '../common/SystemIdResultsTable.jsx';
import { BsPlus } from 'react-icons/bs';
import TableFooter from '@/features/common/table/TableFooter.jsx';
import useTable from '@/hooks/useTable.js';
import DataHeading from '@/features/common/DataHeading.jsx';

const ROW_ANIMATE_COLOR = '#FFFF66';

const ModalityModal = ({ isOpen, onClose, onSave, initialValues, mode }) => {
  useRole('admin');
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const divisionList = useSelector(selectDivisionsList);
  const systemIdList = useSelector(selectSystemIdList);

  const [division, setDivision] = useState(null);
  const [shortName, setShortName] = useState('');
  const [profession, setProfession] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [isEventActive, setIsEventActive] = useState(null);
  const [isDefault, setIsDefault] = useState(true);
  const [systemIds, setSystemIds] = useState([]);
  const [systemIdValue, setSystemIdValue] = useState('');
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [valid, setValid] = useState(true);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(systemIds, page, 4);
  const [systemIdOptions, setSystemIdOptions] = useState(systemIdList);
  const [error, setError] = useState(false);
  const [isIdError, setIsIdError] = useState(false);
  const [isEventSrcError, setIsEventSrcError] = useState(false);
  const [idErrorMsg, setIdErrorMsg] = useState('Unable to add system ID');

  const eventSourceStyles = {
    ...chakraStyles,
    container: (base) => ({
      ...chakraStyles.container(base),
      width: '90%',
    }),
  };

  const [addModality, { isLoading: addModalityLoading, isError: addModalityError, error: addModalityErrorMsg, reset: addModalityReset }] =
    useAddModalitiesMutation();

  const [
    updateModality,
    { isLoading: updateModalityLoading, isError: updateModalityError, error: updateModalityErrorMsg, reset: updateModalityReset },
  ] = useUpdateModalityMutation();

  const { data: specialties, isLoading: specialtiesLoading, isError: specialtiesError, error: specialtiesErrorMsg } = useSpecialtiesQuery();

  const { data: professions, isLoading: professionsLoading, isError: professionsError, error: professionsErrorMsg } = useProfessionsQuery();

  useEffect(() => {
    if (initialValues) {
      const {
        division: divisionValue,
        short_name: shortNameValue,
        profession: professionValue,
        specialty: specialtyValue,
        is_default: isDefaultValue,
        system_ids: systemIdsValue,
      } = initialValues;

      if (divisionValue && divisionList.length) {
        const divisionItem = divisionList.find((item) => item.value === divisionValue);
        if (divisionItem) {
          setDivision(divisionItem);
        }
      }
      if (systemIdsValue?.length) {
        const updatedSystemIds = systemIdsValue.map((id) => ({
          label: id.name,
          value: id.system_id,
          is_active: id.is_active,
          valid: id.system_id !== '',
        }));
        setSystemIds(updatedSystemIds);
      }
      setShortName(shortNameValue || '');
      setProfession(professionValue || null);
      setSpecialty(specialtyValue || null);
      setIsDefault(isDefaultValue ?? true);
    }
  }, [divisionList, initialValues]);

  const handleCleanup = () => {
    setSystemIds([]);
    setDivision(null);
    setShortName('');
    setProfession(null);
    setSpecialty(null);
    setIsDefault(true);
    addModalityReset();
    updateModalityReset();
    setSelectedEvent([]);
    setSystemIdValue('');
  };

  const handleClose = () => {
    onClose();
  };

  const handleDivisionChange = (value) => {
    setDivision(value);
  };

  const handleSpecialtyChange = (value) => {
    setSpecialty(value);
  };

  const handleProfessionsChange = (value) => {
    setProfession(value);
  };

  const createOption = (label) => ({
    label: label.toLowerCase().replace(/(?:[^\w_-])+?/g, ''),
    value: '',
    is_active: false,
    valid: true,
  });

  const handleCreateOption = (options) => {
    const newOption = createOption(options);
    setSystemIdOptions((prev) => [...prev, newOption]);
    setSelectedEvent(newOption);
  };

  const handleChangeOption = (option, { action }) => {
    setSelectedEvent(option);
  };

  const handleToggleSystemId = (e, val) => {
    e.preventDefault();
    const label = val.label;
    const systemId = val.value;

    const updatedSystemIds = systemIds.map((option) => {
      const { is_active, ...rest } = option;
      if (option.label === label && option.value === systemId) {
        return { ...rest, is_active: !option.is_active };
      } else if (option.label === label && option.value !== systemId) {
        return { ...rest, is_active: false };
      }
      return option;
    });
    setSystemIds(updatedSystemIds);
  };

  // used for both staging and cancel
  const handleStageForRemoval = (e, val, overlay) => {
    e.preventDefault();
    const updatedSystemIds = systemIds.map((option) => {
      if (option.label === val?.label && option.value === val?.value) {
        return { ...option, overlay };
      }
      return option;
    });
    setSystemIds(updatedSystemIds);
  };

  const handleDeleteSystemId = (e, val) => {
    e.preventDefault();
    const updatedSystemIds = systemIds.filter((option) => !(option.label === val.label && option.value === val.value));
    setSystemIds(updatedSystemIds);
  };

  const handleSystemId = (e) => {
    setSystemIdValue(e.target.value || '');
  };

  const handleToggleActive = (e) => {
    setIsEventActive(!isEventActive);
  };

  const handleAddSystemId = (e) => {
    e.preventDefault();
    const hasEvent = Boolean(selectedEvent?.label);
    const hasId = Boolean(systemIdValue);
    const newId = {
      is_active: isEventActive,
      label: selectedEvent?.label,
      valid: true,
      value: systemIdValue,
      color: ROW_ANIMATE_COLOR,
    };
    const oldIds = systemIds.map((opt) => ({ ...opt, color: '#ffffff' }));
    // unique constraint on system_id and name
    const systemConstraint = systemIds.find((opt) => opt.label === newId.label && opt.value === newId.value);
    // unique constraint on entity_id and name where is_active = true
    const activeConstraint = systemIds.find(
      (opt) => opt.label === newId.label && Boolean(opt.is_active) === Boolean(newId.is_active) && newId.is_active,
    );
    if (systemConstraint) {
      setError(true);
      setIdErrorMsg('A matching system_id with the same name and value already exists');
      return;
    } else if (activeConstraint) {
      setError(true);
      setIdErrorMsg('An active system_id with the same name already exists');
      return;
    } else if (!hasEvent && hasId) {
      setIsEventSrcError(true);
      setIdErrorMsg('Event Source cannot be blank');
      return;
    } else if (!hasEvent && !hasId) {
      setIsEventSrcError(true);
      setIsIdError(true);
      setIdErrorMsg('System ID and Event Source cannot be blank');
      return;
    } else if (hasEvent && !hasId) {
      setIsIdError(true);
      setIdErrorMsg('System ID cannot be blank');
      return;
    }
    setError(false);
    setIsEventSrcError(false);
    setIsIdError(false);
    setSystemIds([newId, ...oldIds]);
    setSelectedEvent([]);
    setSystemIdValue('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validityCheck = systemIds.map((option) => {
      if (option.value === '') {
        return { ...option, valid: false };
      } else {
        return option;
      }
    });
    if (validityCheck.some((option) => option?.valid === false)) {
      setValid(false);
      setSystemIds(validityCheck);
      return;
    }

    const modalityPayload = {
      division: division?.value,
      short_name: shortName,
      profession_id: profession?.id,
      specialty_id: specialty?.id,
      is_default: isDefault,
      system_ids: systemIds.map((option) => ({
        name: option.label,
        system_id: option.value,
        is_active: option.is_active,
      })),
    };

    if (mode === 'edit' && initialValues?.id) {
      updateModality({ modality: modalityPayload, id: initialValues.id })
        .unwrap()
        .then((payload) => {
          onSave();
          onClose();
        })
        .catch((error) => {
          console.log('Error updating modality', error);
        });
    } else {
      addModality(modalityPayload)
        .unwrap()
        .then((payload) => {
          onSave();
          onClose();
        })
        .catch((error) => {
          console.log('Error adding modality', error);
        });
    }
  };

  return (
    <Modal
      title={mode === 'edit' ? 'Edit Modality' : 'Add Modality'}
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onCloseComplete={handleCleanup}
      onClose={handleClose}
      isCentered
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id={`${mode}_modality`} onSubmit={handleSubmit}>
          <ModalHeader>
            <HStack>
              <TbLayoutCards />
              <Text>{mode === 'edit' ? 'Edit Modality' : 'Add Modality'}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} position={'relative'}>
              <LoaderOverlay loading={addModalityLoading || updateModalityLoading || professionsLoading || specialtiesLoading} />
              {(addModalityError || updateModalityError) && (
                <AlertComponent
                  status="error"
                  title={mode === 'edit' ? 'Error updating modality' : 'Error adding modality'}
                  description={
                    addModalityErrorMsg?.status === 409 || updateModalityErrorMsg?.status === 409
                      ? 'Modality already exists'
                      : addModalityErrorMsg?.status === 422 || updateModalityErrorMsg?.status === 422
                      ? 'Invalid data. Please check the fields and try again.'
                      : addModalityErrorMsg?.data?.detail || updateModalityErrorMsg?.data?.detail
                  }
                />
              )}
              {(specialtiesError || professionsError) && (
                <AlertComponent
                  status="error"
                  title="Error fetching specialties or professions"
                  description={specialtiesErrorMsg?.data?.detail || professionsErrorMsg?.data?.detail}
                />
              )}
              <FormControl isInvalid={false} aria-label={'division-control'}>
                <FormRow id="division" label="Division" templateColumns="120px auto" gap={0} width="100%">
                  <Select
                    id="division"
                    aria-label="division"
                    placeholder="Select a Division"
                    focusBorderColor="brand.700"
                    options={divisionList}
                    value={division}
                    onChange={handleDivisionChange}
                    isClearable={true}
                    isSearchable
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </FormRow>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'short-name-control'}>
                <FormRow id="short_name" label="Short Name" templateColumns="120px auto" gap={0} width="100%">
                  <Input
                    id="short_name"
                    focusBorderColor={'brand.700'}
                    type="text"
                    isRequired={false}
                    isDisabled={false}
                    value={shortName}
                    onChange={(e) => setShortName(e.target.value || '')}
                  />
                </FormRow>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'is-default-control'}>
                <FormRow id="is_default" label="Default" templateColumns="120px auto" gap={0} width="100%">
                  <Switch
                    colorScheme="cyan"
                    id="is_default"
                    aria-label="is_default"
                    isChecked={isDefault}
                    onChange={(e) => setIsDefault(e.target.checked)}
                    mb={0.5}
                  />
                </FormRow>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'specialty-control'}>
                <FormRow id="specialty" label="Specialty" templateColumns="120px auto" gap={0} width="100%">
                  <Select
                    id="specialty"
                    aria-label="specialties"
                    placeholder="Select a Specialty"
                    focusBorderColor="brand.700"
                    options={specialties?.items}
                    value={specialty}
                    onChange={handleSpecialtyChange}
                    isClearable={true}
                    isSearchable
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                </FormRow>
              </FormControl>
              <FormControl isInvalid={false} aria-label={'profession-control'}>
                <FormRow id="profession" label="Profession" templateColumns="120px auto" gap={0} width="100%">
                  <Select
                    id="profession"
                    aria-label="profession"
                    placeholder="Select a Profession"
                    focusBorderColor="brand.700"
                    options={professions?.items}
                    value={profession}
                    onChange={handleProfessionsChange}
                    isClearable={true}
                    isSearchable
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                </FormRow>
              </FormControl>
              <DataHeading label="System ID's" empty={!systemIds.length} bg="orange" />
              <Card width="100%" variant="outline" bg="gray.50">
                <CardBody>
                  <VStack spacing={4}>
                    <FormRow id="event-source-row" label="System ID" templateColumns="120px auto" gap={0} width="100%">
                      <HStack>
                        <FormControl isInvalid={isEventSrcError || error} aria-label="event source control">
                          <CreatableSelect
                            id="event-source"
                            aria-label="event source"
                            chakraStyles={eventSourceStyles}
                            focusBorderColor="brand.700"
                            options={systemIdOptions}
                            value={selectedEvent}
                            isMulti={false}
                            isSearchable
                            isClearable={true}
                            onChange={handleChangeOption}
                            onCreateOption={handleCreateOption}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.label}
                            placeholder="Select or Create Source"
                          />
                        </FormControl>
                        <FormControl isInvalid={isIdError || error} aria-label="system id">
                          <FormRow id="system-id" label="ID" templateColumns="50px auto" gap={0} width="100%">
                            <Input
                              id="system-id"
                              aria-label="system-id"
                              focusBorderColor={'brand.700'}
                              type="text"
                              bg="white"
                              isRequired={false}
                              isDisabled={false}
                              value={systemIdValue}
                              placeholder="System ID"
                              onChange={handleSystemId}
                            />
                          </FormRow>
                        </FormControl>
                      </HStack>
                    </FormRow>
                    <FormControl isInvalid={false} aria-label="is active control">
                      <FormRow id="is-active-row" label="Active" templateColumns="120px auto" gap={0} width="100%">
                        <HStack position="relative" justifyContent="space-between">
                          <Box width="50%">
                            <Switch
                              id="is-active"
                              aria-label="is active"
                              colorScheme="cyan"
                              isChecked={isEventActive}
                              onChange={handleToggleActive}
                              mb={0.5}
                            />
                          </Box>
                          <Box>
                            <Button size="sm" leftIcon={<BsPlus />} variant="cyan" type="submit" onClick={handleAddSystemId}>
                              Add System ID
                            </Button>
                          </Box>
                        </HStack>
                      </FormRow>
                    </FormControl>
                  </VStack>
                </CardBody>
              </Card>
              <VStack width={'100%'} alignItems="start">
                {isIdError || isEventSrcError || error ? (
                  <AlertComponent status="warning" title="Warning" description={idErrorMsg} mb={2} />
                ) : (
                  <Box as="span" />
                )}
                <Box width="100%" height="273px" overflow="hidden" bg="white" borderColor="gray.200" borderWidth="1px" borderRadius="9px">
                  <SystemIdResultsTable
                    data={slice}
                    toggleActive={handleToggleSystemId}
                    handleRemove={handleStageForRemoval}
                    handleDelete={handleDeleteSystemId}
                    highlight={ROW_ANIMATE_COLOR}
                  />
                </Box>
              </VStack>
              <Box width="100%" height="24px">
                <TableFooter range={range} setPage={setPage} page={page} />
              </Box>
              <HStack width="100%" py={3} justifyContent={'end'}>
                <Button variant="purple" type="submit" isDisabled={!valid || error || isEventSrcError || isIdError}>
                  {mode === 'edit' ? 'Save' : 'Submit'}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

ModalityModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  initialValues: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalityModal;
