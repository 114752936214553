import { unwrapResult } from '@reduxjs/toolkit';

export const unsubscribeUserAction = async (unsubscribeUser, userId, subscription, setList) => {
  try {
    const result = await unsubscribeUser({ userId: userId, subscription: subscription });
    unwrapResult(result);
    console.log('User unsubscribed successfully:', result);

    // Update filtered list by removing unsubscribed user
    setList((prev) => prev.filter((user) => user.id !== userId));
  } catch (error) {
    console.log(error);
    console.log('Error unsubscribing user:', error);
  }
};

export const subscribeUserAction = async (subscribeUser, userId, subscription) => {
  try {
    const result = await subscribeUser({ userId: userId, subscription: subscription });
    unwrapResult(result);
    console.log('User subscribed successfully:', result);
  } catch (error) {
    console.log('Error subscribing user:', error);
  }
};
