import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Box, Text, VStack } from '@chakra-ui/react';
import { BsBuildingAdd } from 'react-icons/bs';
import FacilitySearch from './FacilitySearch';
import useTable from '@/hooks/useTable';
import { useRemoveFacilityMutation } from '@/app/services/nucleus';
import TableFooter from '../common/table/TableFooter';
import { isArrayEmpty, isRealValue } from '@/utils/helpers';
import FacilityResultsTable from './FacilityResultsTable';
import { removeFacilityAction } from '@/features/facility/facilityHelper';

const AddFacility = ({ id, isOpen, onClose, onSaveHandler, isLoading, isSuccess, isError, errorMsg }) => {
  const initialRef = useRef(null);
  const inputRef = useRef(null);
  const finalRef = useRef(null);
  const [facility, setFacility] = useState(null);
  const [stagedFacilities, setStagedFacilities] = useState([]);
  const [showError, setShowError] = useState(false);
  const [facilityInput, setFacilityInput] = useState('');
  const [stagingRemoval, setStagingRemoval] = useState(false);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(stagedFacilities, page, 4);

  const [
    removeFacility,
    { isLoading: removeFacilityLoading, isSuccess: facilityRemovedSuccessfully, isError: removeFacilityError, error: removeFacilityErrorMsg, reset },
  ] = useRemoveFacilityMutation();

  useEffect(() => {
    if (isError) {
      setShowError(true);
      setFacility(null);
      setFacilityInput('');
    }
  }, [isError]);

  useEffect(() => {
    if (isOpen) {
      setFacility(null);
      setFacilityInput('');
      setStagedFacilities([]);
      setStagingRemoval(false);
      setShowError(false);
    }
  }, [isOpen]);

  // successfully added a new facility
  useEffect(() => {
    if (isSuccess) {
      setStagedFacilities((prevState) => {
        return [...prevState, facility];
      });
      setFacility(null);
      setStagingRemoval(false);
      setFacilityInput('');
    }
    isRealValue(inputRef) && inputRef?.current?.focus();
  }, [isSuccess]);

  useEffect(() => {
    if (facilityRemovedSuccessfully) {
      const newFacilities = stagedFacilities.filter((fac) => fac?.overlay !== true);
      setStagedFacilities(newFacilities);
      setFacility(null);
      setFacilityInput('');
      setStagingRemoval(false);
      reset();
    }
  }, [facilityRemovedSuccessfully]);

  const handleAddFacility = (e, id) => {
    e.preventDefault();
    onSaveHandler(e, id);
  };

  const handleClose = () => {
    setFacility(null);
    setFacilityInput('');
    onClose();
  };

  const handleRemoveFacilityActions = (e, facilityId) => {
    e.preventDefault();
    const facilitiesWithOverlay = stagedFacilities.map((fac) => {
      if (fac.id === facilityId) {
        fac = { ...fac, overlay: true };
      }
      return fac;
    });
    setStagedFacilities(facilitiesWithOverlay);
    setStagingRemoval(true);
  };

  const cancelFacilityDelete = (e, id) => {
    e.preventDefault();
    setStagingRemoval(false);
    const facilitiesWithOverlay = stagedFacilities.map((fac) => {
      if (fac.id === id) {
        fac = { ...fac, overlay: false };
      }
      return fac;
    });
    setStagedFacilities(facilitiesWithOverlay);
  };

  const confirmFacilityDelete = (e, facilityId) => {
    removeFacilityAction(removeFacility, id, facilityId);
  };

  const handleFacilityChange = (value, action) => {
    setFacility(value);
    setFacilityInput(value?.label);
    setShowError(false);
  };

  const handleStateChange = (value, action, facility) => {
    if (action === 'select-option') {
      // clear the facility alert box
      if (facility?.label) {
        setFacility(null);
        setFacilityInput('');
      }
    } else if (action === 'clear') {
      setFacility(null);
      setFacilityInput('');
    }
  };

  return (
    <Modal
      title="Add Facility"
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id="add_facility">
          <ModalHeader>
            <HStack>
              <BsBuildingAdd />
              <Text>Add Facility</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <HStack id="add_facility" width="100%">
                <FacilitySearch
                  initialRef={inputRef}
                  onFacilityChange={handleFacilityChange}
                  onStateChange={handleStateChange}
                  facilityPlaceholder="Search Facilities"
                  facility={facility}
                  input={facilityInput}
                  facilityMaxWidth={'480px'}
                  isReset={isOpen}
                  width="100%"
                />
                <Button
                  isLoading={isLoading}
                  isDisabled={isArrayEmpty(facility)}
                  variant="cyan"
                  onClick={(e) => handleAddFacility(e, facility?.value)}
                >
                  Add
                </Button>
              </HStack>
              <FacilityResultsTable
                data={slice}
                loading={removeFacilityLoading}
                facilityName={facility?.name}
                handleDelete={confirmFacilityDelete}
                handleCancel={cancelFacilityDelete}
                isDisabled={isLoading || removeFacilityLoading || stagingRemoval}
                handleRemove={handleRemoveFacilityActions}
                showEmpty={isArrayEmpty(stagedFacilities)}
                showAddError={Boolean(showError && errorMsg)}
                showRemoveError={Boolean(showError && removeFacilityError)}
                addErr={errorMsg?.data?.detail}
                removeErr={removeFacilityErrorMsg?.data?.detail}
              />
              {range && range.length > 1 && (
                <Box width="100%">
                  <TableFooter range={range} setPage={setPage} page={page} />
                </Box>
              )}
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

AddFacility.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  errorMsg: PropTypes.object,
};

export default AddFacility;
