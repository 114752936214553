import { nucleusApi } from '@/app/services/nucleus';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { NO_RESULTS, LOADED, SALES, ERROR, LOADING } from '@/utils/constants';
import { parseNucleusError } from '@/utils/helpers';

const initialState = {
  name: '',
  username: '',
  id: '',
  email: '',
  admin: false,
  superadmin: false,
  roles: [],
  password: '',
  users: [],
  salesReps: [],
  status: '',
  errorMessage: '',
  targetSalesRep: null,
  searchUserStatus: '',
  userSortBy: 'first_name',
  userSortDirection: 'asc',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserUsername: (state, { payload }) => {
      state.username = payload;
    },
    setTargetSalesRep: (state, { payload }) => {
      state.targetSalesRep = payload;
    },
    setUserLoggedOut: (state, { payload }) => {
      state.id = '';
      state.roles = [];
      state.admin = false;
      state.superadmin = false;
      state.email = '';
      state.name = '';
    },
    setUserData: (state, { payload }) => {
      const { id, roles, admin, superadmin, email, name, username } = payload;
      state.id = id;
      state.roles = roles;
      state.admin = admin;
      state.superadmin = superadmin;
      state.email = email;
      state.username = username;
      state.name = name;
    },
    setAuthUserId: (state, { payload }) => {
      state.id = payload;
    },
    setUserSearchStatus: (state, { payload }) => {
      state.searchUserStatus = payload;
    },
    setUserSortBy: (state, { payload }) => {
      state.userSortBy = payload;
    },
    setUserSortDirection: (state, { payload }) => {
      state.userSortDirection = payload;
    },
    setUserPassword: (state, { payload }) => {
      state.password = btoa(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(nucleusApi.endpoints.getUsers.matchPending, (state) => {
        state.status = LOADING;
      })
      .addMatcher(nucleusApi.endpoints.getUsers.matchFulfilled, (state, { payload }) => {
        if (payload && payload?.items) {
          state.users = payload.items;
          state.salesReps = payload.items.filter((i) => i.role && i.role.toLowerCase() === SALES);
          if (payload.items.length === 0) {
            state.status = NO_RESULTS;
          } else {
            state.status = LOADED;
          }
        } else {
          state.status = NO_RESULTS;
        }
        state.errorMessage = '';
      })
      .addMatcher(nucleusApi.endpoints.getUsers.matchRejected, (state, result) => {
        state.status = ERROR;
        const { payload } = result;
        state.errorMessage = parseNucleusError(payload);
      });
    builder.addMatcher(nucleusApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      if (payload) {
        if ('user' in payload && payload?.user?.first_name) {
          const { user } = payload;
          state.name = `${user?.first_name} ${user?.last_name}`;
          state.username = user?.username;
          state.email = user?.email;
          state.id = user?.id;
          state.admin = Boolean(user?.admin);
          state.superadmin = Boolean(user?.superadmin);
          state.roles = user?.roles;
        }
      }
    });
  },
});

export const {
  setTargetSalesRep,
  setUserUsername,
  setUserData,
  setUserLoggedOut,
  setUserPassword,
  setAuthUsers,
  setAuthUserId,
  setUserSortBy,
  setUserSortDirection,
  setUserSearchStatus,
} = userSlice.actions;

export const selectUserName = (state) => state.user.name;
export const selectUserEmail = (state) => state.user.email;
export const selectTargetSalesRep = (state) => state.user.targetSalesRep;
export const selectUserUsername = (state) => state.user.username;
export const selectUserAdmin = (state) => state.user.admin;
export const selectUserSuperadmin = (state) => state.user.superadmin;
export const selectUserRoles = (state) => state.user.roles;
export const selectUserPassword = (state) => atob(state.user.password);
export const selectUsers = (state) => state.user.users;
export const selectUsersList = createSelector(selectUsers, (users) => {
  return Array.isArray(users)
    ? users.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
        role: user?.role,
      }))
    : [];
});
export const selectUsersStatus = (state) => state.user.status;
export const selectUserSearchStatus = (state) => state.user.searchUserStatus;
export const selectUserSortBy = (state) => state.user.userSortBy;
export const selectUserSortDirection = (state) => state.user.userSortDirection;
export const selectUsersErrorMessage = (state) => state.user.errorMessage;
export const selectSalesReps = (state) => state.user.salesReps;
export default userSlice.reducer;
