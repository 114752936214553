import { useLazySearchUsersQuery } from '@/app/services/nucleus';
import PropTypes from 'prop-types';
import { FormControl, FormErrorMessage, HStack } from '@chakra-ui/react';
import { AsyncSelect, createFilter } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { CustomOption, SingeValueUser, UserSearchInput } from './Option';

const UserSearch = ({
  id,
  ariaLabel,
  initialRef,
  userDisabled,
  userPlaceholder,
  onUserChange,
  onUserInputChange,
  user,
  input,
  isReset,
  width,
  isInvalid,
  selectedSubscription,
  userSearchError,
  userRequiredError,
}) => {
  const [userInput, setUserInput] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [localUser, setLocalUser] = useState(null);

  const [searchUsers, { isError: isSearchUsersError, error: searchUsersError }] = useLazySearchUsersQuery();

  useEffect(() => {
    if (isReset) {
      setUserInput('');
      setSearchResults(null);
    }
  }, [isReset]);

  useEffect(() => {
    setLocalUser(user);
  }, [user]);

  useEffect(() => {
    setUserInput(input);
  }, [input]);

  useEffect(() => {
    if (selectedSubscription) {
      loadOptions(userInput, () => null);
    }
  }, [selectedSubscription]);

  const handleStagedUser = (value, { action }) => {
    setLocalUser(value ?? null);
    setUserInput(value?.first_name ?? '');
    typeof onUserChange === 'function' && onUserChange(value, action);
    if (action === 'clear') {
      setLocalUser(null);
    }
  };

  const handleInputChange = (inputValue, { action }) => {
    typeof onUserInputChange === 'function' && onUserInputChange(inputValue, action);
    if (action === 'input-change') {
      setUserInput(inputValue);
    }
  };

  const loadOptions = (inputValue, callback) => {
    let payload = { search: inputValue };

    searchUsers(payload)
      .unwrap()
      .then((payload) => {
        console.log(payload);
        // filter out users already subscribed to the selected subscription
        if (selectedSubscription) {
          payload = payload.filter((user) => !user.subscriptions.includes(selectedSubscription.value));
        }
        setSearchResults(payload);
        return callback(payload);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <HStack spacing={4} alignItems="start" width={width ? width : 'auto'}>
      <FormControl width="100%" isInvalid={isInvalid || isSearchUsersError} aria-label="user_search_control">
        <AsyncSelect
          id={id ? id : 'user_search'}
          defaultOptions={userInput !== '' ? searchResults : null}
          isDisabled={userDisabled}
          ref={initialRef}
          aria-label={ariaLabel ? ariaLabel : 'user_search'}
          chakraStyles={{
            dropdownIndicator: (defaultStyles) => ({
              ...defaultStyles,
              display: 'none',
            }),
          }}
          focusBorderColor="brand.700"
          value={localUser}
          onChange={handleStagedUser}
          onInputChange={handleInputChange}
          isClearable
          isSearchable
          placeholder={userPlaceholder}
          loadOptions={(value, cb) => loadOptions(value, cb)}
          options={searchResults}
          noOptionsMessage={() => null}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            Input: UserSearchInput,
            SingleValue: SingeValueUser,
            Option: CustomOption,
          }}
          getOptionLabel={(option) => `${option?.first_name} ${option?.last_name} ${option?.email}`}
          getOptionValue={(option) => option?.value}
        />
        <FormErrorMessage aria-label="user_search_error">
          {userRequiredError ? userRequiredError : userSearchError ? userSearchError : searchUsersError?.data?.detail}
        </FormErrorMessage>
      </FormControl>
    </HStack>
  );
};

UserSearch.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  initialRef: PropTypes.object,
  userDisabled: PropTypes.bool,
  userPlaceholder: PropTypes.string,
  onUserChange: PropTypes.func,
  onUserInputChange: PropTypes.func,
  user: PropTypes.object,
  input: PropTypes.string,
  isReset: PropTypes.bool,
  width: PropTypes.string,
  isInvalid: PropTypes.bool,
  selectedSubscription: PropTypes.object,
  userSearchError: PropTypes.string,
  userRequiredError: PropTypes.string,
};

export default UserSearch;
