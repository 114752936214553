import { Badge, Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import PropTypes from 'prop-types';
import AnimatedRow from '../common/motion/AnimatedRow';
import ModalOverlayComponent from '../common/ModalOverlayComponent';
import TableButton from '../common/table/TableButton';
import { BsExclamation, BsTrash } from 'react-icons/bs';
import LoadingWrapper from '../common/motion/LoadingWrapper';
import AlertComponent from '../common/AlertComponent';

const SubscribedUserResultsTable = ({
  data,
  loading,
  userName,
  subscriptionName,
  isDisabled,
  handleDelete,
  handleCancel,
  handleRemove,
  showEmpty,
  showAddError,
  showRemoveError,
  addErr,
  removeErr,
}) => {
  return (
    <TableContainer pt={6} width={'100%'} style={{ position: 'relative' }} minH={360}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th width={165}>Name</Th>
            <Th width={165}>Email</Th>
            <Th width={300}>Current Subscription Type</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((val, i) => (
              <AnimatedRow key={i} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                {val.overlay ? (
                  <ModalOverlayComponent
                    loading={loading}
                    confirmText="Are you sure you want to unsubscribe this user?"
                    handleDelete={(e) => handleDelete(e, val?.id)}
                    handleCancel={(e) => handleCancel(e, val?.id)}
                    textStyle={{ maxWidth: '90%', whiteSpace: 'normal', wordWrap: 'break-word' }}
                  />
                ) : (
                  <React.Fragment>
                    <Td>{`${val?.first_name} ${val?.last_name}`}</Td>
                    <Td>{val?.email}</Td>
                    <Td>
                      <Box display="flex" flexDirection={'column'} gap={1}>
                        {val?.subscriptions.includes(subscriptionName) && (
                          <Badge colorScheme="purple" mr={1} alignSelf="flex-start">
                            {subscriptionName}
                          </Badge>
                        )}
                      </Box>
                    </Td>
                    <Td>
                      <Tooltip hasArrow placement="top" label="Unsubscribe">
                        <Box p="0" as="span">
                          <TableButton isDisabled={isDisabled} aria-label="unsubscribe" icon={<BsTrash />} onClick={(e) => handleRemove(e, val.id)} />
                        </Box>
                      </Tooltip>
                    </Td>
                  </React.Fragment>
                )}
              </AnimatedRow>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>
      {showEmpty && (
        <LoadingWrapper py={16} height="40px" alignItems="center">
          <Text fontWeight="bold">
            Subscribe Users to{' '}
            <Text as="span" fontWeight="bold" color="brand.900">
              {subscriptionName}
            </Text>{' '}
          </Text>
        </LoadingWrapper>
      )}
      {showAddError && (
        <AlertComponent icon={<BsExclamation style={{ height: '24px', width: '24px' }} />} status="error" title={userName} description={addErr} />
      )}
      {showRemoveError && (
        <AlertComponent icon={<BsExclamation style={{ height: '24px', width: '24px' }} />} status="error" title={userName} description={removeErr} />
      )}
    </TableContainer>
  );
};

SubscribedUserResultsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  userName: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
  handleRemove: PropTypes.func,
  showEmpty: PropTypes.bool,
  showAddError: PropTypes.bool,
  showRemoveError: PropTypes.bool,
  addErr: PropTypes.string,
  removeErr: PropTypes.string,
  subscriptionName: PropTypes.string,
};

export default SubscribedUserResultsTable;
