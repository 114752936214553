import PropTypes from 'prop-types';
import { chakra, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import LoadingWrapper from '@/features/common/motion/LoadingWrapper';

const TableComponent = ({ table, columns, showNoResults, maxWidth, rowLoading, getRowProps }) => {
  const getRowPropsInner = (row) => {
    if (typeof getRowProps === 'function') {
      return getRowProps(row);
    }
  };

  return (
    <Table variant="simple" maxWidth={maxWidth || '100%'}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const meta = header.column.columnDef.meta;
              const isSortable = header.column.columnDef?.disableSortBy !== true;
              const widthStrategy = meta?.widthStrategy ?? 'px';
              const size = header.getSize() !== 150 ? `${header.getSize()}${widthStrategy}` : 'auto';
              return (
                <Th
                  key={header.id}
                  onClick={isSortable ? header.column.getToggleSortingHandler() : null}
                  isNumeric={meta?.isNumeric}
                  style={{ minWidth: '100px', width: size }}
                  textAlign={meta?.align ?? 'left'}
                >
                  <chakra.span style={{ cursor: isSortable ? 'pointer' : 'default' }}>
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <BsChevronDown aria-label="sorted descending" style={{ display: 'inline-block' }} />
                        ) : (
                          <BsChevronUp aria-label="sorted ascending" style={{ display: 'inline-block' }} />
                        )
                      ) : null}
                    </chakra.span>
                  </chakra.span>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {showNoResults ? (
          <Tr>
            <Td colSpan={columns.length} textAlign={'center'}>
              No Results
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map((row) => (
            <Tr key={row.id} bgColor={row?.original?.color ? row.original.color : 'white'} {...getRowPropsInner(row)}>
              {row?.original?.edit && rowLoading ? (
                <Td colSpan={columns.length}>
                  <LoadingWrapper height={'40px'} alignItems="center">
                    <Spinner />
                  </LoadingWrapper>
                </Td>
              ) : (
                row.getVisibleCells().map((cell) => {
                  const meta = cell.column.columnDef.meta;
                  return (
                    <Td key={cell.id} isNumeric={meta?.isNumeric} style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', height: '1px' }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })
              )}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

TableComponent.propTypes = {
  table: PropTypes.object,
  columns: PropTypes.array,
  showNoResults: PropTypes.bool,
  maxWidth: PropTypes.string,
  rowLoading: PropTypes.bool,
  getRowProps: PropTypes.func,
};
export default TableComponent;
